<template>
  <div>
    <Toast />
    <TimBreadCrumb></TimBreadCrumb>
    <div class="w-12 flex p-align-center p-justify-center">
      <div class="doc">
        <div class="actionbar">
          <div class="grid">
            <div class="col-12 pt-4 pr-8 flex justify-content-end">
              <i
                v-if="!editMode"
                class="pi pi-file-pdf mr-3 cursor-pointer"
                @click="exportPDF()"
              ></i>
              <i
                v-if="!editMode"
                class="pi pi-file mr-3 cursor-pointer"
                @click="exportWORD()"
              ></i>
              <i
                v-if="!editMode"
                class="pi pi-pencil cursor-pointer"
                data-cy="edit"
                @click="editMode = true"
              ></i>
              <i
                v-if="editMode"
                class="pi pi-check mr-3 cursor-pointer"
                data-cy="check"
                @click="save()"
              ></i>
              <i
                v-if="editMode"
                class="pi pi-times cursor-pointer"
                data-cy="cancel"
                @click="reset()"
              ></i>
            </div>
          </div>
        </div>
        <div v-if="page === 0" class="doc-header">
          <div class="person">
            <div>
              <strong>{{ employee.firstname }} {{ employee.lastname }}</strong>
            </div>

            <div v-if="!editMode" class="p-text-bold p-text-italic">
              {{ employee_graduation.origin }}
            </div>
            <InputText
              v-else
              v-model="employee_graduation.dirtyValue"
              data-cy="graduation"
            ></InputText>

            <div>{{ employee.jobTitle }}</div>

            <div v-if="!editMode">{{ employee_section.origin }}</div>
            <InputText
              v-else
              v-model="employee_section.dirtyValue"
              data-cy="section"
            ></InputText>
          </div>

          <img alt="Logo" src="@/assets/logo_large.png" />
        </div>
        <div v-else class="doc-header small">
          <div class="person">
            <div>
              <strong>{{ employee.firstname }} {{ employee.lastname }}</strong>
              - {{ employee.jobTitle }}
              {{ employee.profile?.section }}
            </div>
          </div>
        </div>
        <div class="blue-emphasis"></div>
        <div id="body" :class="page > 0 ? 'large' : ''" class="body">
          <div class="divider"></div>
          <div :key="renderKey" class="grid">
            <!-- Anzeigemodus -->
            <template v-if="!editMode">
              <template
                v-for="element of profileSectionList.filter(
                  (arrayEntry) =>
                    arrayEntry.value.entries.filter(
                      (entry) => entry.page === page
                    ).length > 0
                )"
                :key="element.key"
              >
                <!-- left column -->
                <div class="col-4 flex p-justify-center">
                  <template v-if="element.value.entries[0].page === page">
                    <template
                      v-if="
                        [
                          'PROFIL',
                          'SKILLS',
                          'PROJEKTAUSZUG',
                          'ZERTIFIKATE',
                          'LANGUAGES',
                        ].includes(element.key)
                      "
                    >
                      {{ $t("skills." + element.key) }}
                    </template>
                    <template v-else>
                      {{ element.key }}
                    </template>
                  </template>
                </div>
                <!-- right column -->
                <div class="profile-value col-8 p-text-justify pr-8">
                  <template v-for="sub of element.value.entries" :key="sub">
                    <div
                      v-if="
                        page === sub.page &&
                        ((element.key === 'SKILLS' &&
                          !['SECTOR', 'METHOD', 'ROLE'].includes(
                            sub.headline.origin
                          )) ||
                          element.key !== 'SKILLS')
                      "
                      :class="page === 0 && element.key === 0 ? 'mt-4' : ''"
                      :data-id="sub.uuid"
                      class="pb-4"
                      data-cy="valueReadonly"
                    >
                      <h5 v-if="sub.headline.origin">
                        {{
                          sub.translateHeadline
                            ? $t("skills." + sub.headline.origin)
                            : sub.headline.origin
                        }}
                      </h5>
                      <template v-if="typeof sub.value.origin === 'string'">
                        <div class="ml-5">{{ sub.value.origin }}</div>
                      </template>
                      <template v-else>
                        <ul>
                          <li v-for="obj of sub.value.origin" :key="obj">
                            {{ obj.value }}
                          </li>
                        </ul>
                      </template>
                    </div>
                  </template>
                </div>
              </template>
            </template>
            <!-- Bearbeitungsmodus -->
            <template v-else>
              <template
                v-for="element of profileSectionList.filter(
                  (arrayEntryEditingMode) =>
                    arrayEntryEditingMode.value.entries.filter(
                      (entry) => entry.page === page
                    ).length > 0
                )"
                :key="element.key"
              >
                <div class="col-4 flex p-justify-center">
                  <!--  time period-->
                  <template v-if="element.value.entries[0].page === page">
                    <div v-if="isProfileEntry(element.key)" class="grid">
                      <div class="ml-4 col-11">
                        <div class="p-inputgroup min-w-0">
                          <Calendar
                            v-model="element.value.dirtyBeginDate"
                            data-cy="entryBegin"
                            date-format="mm/yy"
                            view="month"
                          />
                          <span class="p-inputGroup-addon">/</span>
                          <Calendar
                            v-model="element.value.dirtyEndDate"
                            data-cy="entryEnd"
                            date-format="mm/yy"
                            view="month"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- heading on the left side -->
                    <template
                      v-else-if="
                        [
                          'PROFIL',
                          'SKILLS',
                          'PROJEKTAUSZUG',
                          'ZERTIFIKATE',
                          'LANGUAGES',
                        ].includes(element.key)
                      "
                    >
                      {{ $t("skills." + element.key) }}
                    </template>
                    <template v-else>
                      {{ element.key }}
                    </template>
                  </template>
                </div>
                <!-- right column -->
                <div class="profile-value col-8 p-text-justify pr-8">
                  <template v-for="sub of element.value.entries" :key="sub">
                    <div
                      v-if="
                        page === sub.page &&
                        ((element.key === 'SKILLS' &&
                          !['SECTOR', 'METHOD', 'ROLE'].includes(
                            sub.headline.origin
                          )) ||
                          element.key !== 'SKILLS')
                      "
                      :class="page === 0 && element.key === 0 ? 'mt-4' : ''"
                      :data-id="sub.uuid"
                      class="pb-4 grid"
                    >
                    {{ console.log('sub', sub) }}
                      <div class="col-8">
                        <h5 v-if="sub.headline.origin">
                          <template v-if="sub.translateHeadline">
                            {{ $t("skills." + sub.headline.origin) }}
                          </template>
                          <div v-else>
                            <AITextOptimizer
                              :original-text="sub.headline.dirtyValue"
                              :section="element.key"
                              :uuid="sub.uuid"
                              @accepted="optimizeProjectTitle"
                            ></AITextOptimizer>

                            <Textarea
                              v-model="sub.headline.dirtyValue"
                              :auto-resize="true"
                              cols="54"
                              data-cy="entryDescription"
                              rows="2"
                            />
                          </div>
                        </h5>
                      </div>
                      <div class="col-4 pr-0" data-cy="skill-item">
                        <Button
                          v-if="element.key === 'PROJEKTAUSZUG'"
                          data-cy="addEntry"
                          @click="addProfileEntry()"
                          ><i class="pi pi-plus mr-3" />{{
                            $t("skills.newEntry")
                          }}
                        </Button>
                      </div>
                      <template v-if="typeof sub.value.origin === 'string'">
                        <div class="ml-5">
                          <div
                            v-if="sub.options"
                            class="flex justify-content-center"
                          >
                            <div>
                              <MultiSelect
                                v-model="sub.model.dirtyValue"
                                :options="sub.options"
                                class="w-full md:w-20rem"
                                display="chip"
                                optionLabel="name"
                              >
                                <template #chip="slotProps">
                                  <div>
                                    <span>
                                      {{ slotProps.value.name }}
                                    </span>
                                  </div>
                                </template>
                              </MultiSelect>
                            </div>
                            <div class="flex flex-column gap-2">
                              <InputText
                                id="newEntryField"
                                placeholder="Add new entry"
                                @keyup.enter="addNewValueToModel($event, sub)"
                              />
                              <small id="new-entry-help"
                                >Confirm with ENTER.</small
                              >
                            </div>
                          </div>
                          <div v-else>
                            <div class="flex flex-row column-gap-2">
                              <AITextOptimizer
                                :original-text="sub.value.dirtyValue"
                                :section="element.key"
                                :uuid="sub.uuid"
                                @accepted="optimizeProfileDescription"
                              ></AITextOptimizer>
                              <div>
                                <button
                                  v-if="element.key === 'ZERTIFIKATE'"
                                  style="margin-bottom: 1rem"
                                  @click="deleteCertificate(sub)"
                                >
                                  {{ $t("profile.delete") }}
                                </button>
                              </div>
                            </div>
                            <Textarea
                              v-model="sub.value.dirtyValue"
                              :auto-resize="true"
                              cols="54"
                              data-cy="description"
                              rows="5"
                            />
                          </div>
                        </div>
                      </template>
                      <template v-else-if="sub.model.origin">
                        <ul>
                          <li
                            v-for="(item, index) of sub.model.dirtyValue"
                            :key="item"
                          >
                            <div class="p-inputgroup">
                              <InputText
                                v-model="sub.model.dirtyValue[index].value"
                                class="w-12"
                              ></InputText>
                              <span class="p-inputGroup-addon">
                                <i
                                  :title="$t('equipment.delete')"
                                  class="pi pi-minus-circle"
                                  data-cy="deleteDetail"
                                  style="cursor: pointer"
                                  @click="
                                    deleteDescription(
                                      sub.model.dirtyValue,
                                      index
                                    )
                                  "
                                ></i>
                                <AITextOptimizer
                                  :compact="true"
                                  :index="index"
                                  :original-text="
                                    sub.model.dirtyValue[index].value
                                  "
                                  :uuid="sub.uuid"
                                  @accepted="optimizeProjectDescItem"
                                ></AITextOptimizer>
                              </span>
                            </div>
                          </li>
                          <li>
                            <div class="p-inputgroup">
                              <InputText
                                ref="descriptionInput"
                                class="w-12"
                                data-cy="addDetail"
                                @change="addDescription(sub.model.dirtyValue)"
                              ></InputText>
                            </div>
                          </li>
                        </ul>
                      </template>
                    </div>
                  </template>
                </div>
              </template>
            </template>

            <select id="skillTemplate" class="p-d-none">
              <option
                v-for="skilltype of employee?.profile?.skillTypes"
                :key="skilltype"
                :value="skilltype"
              >
                {{ $t("skills." + skilltype) }}
              </option>
            </select>

            <select id="profileStorageTemplate" class="p-d-none">
              <template
                v-for="skilltype of employee?.profile?.profileStorageTypes"
                :key="skilltype"
              >
                <option v-if="skilltype !== 'DESCRIPTION'" :value="skilltype">
                  {{ $t("skills." + skilltype) }}
                </option>
              </template>
            </select>
          </div>
        </div>
        <!-- Footer -->
        <div
          id="footer"
          class="light-blue-footer flex justify-content-center align-items-center"
        >
          <span>
            <strong>{{ company.name }}</strong> | {{ company.street }} |
            {{ company.plz }} {{ company.city }} | {{ company.tel }} |
            {{ company.mail }}
          </span>
        </div>
        <div class="blue-emphasis bottom page">
          <span
            v-if="page > 0"
            class="pi pi-chevron-left mr-2"
            data-cy="prevPage"
            @click="switchPage(-1)"
          ></span>
          {{ $t("skills.page") }} {{ page + 1 }}
          <span
            v-if="page < maxPage"
            class="pi pi-chevron-right"
            data-cy="nextPage"
            @click="switchPage(1)"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmployeeService from "@/service/EmployeeService";
import AITextOptimizer from "./AITextOptimizer.vue";
import ProfileService from "@/service/ProfileService";
import PdfService from "@/service/PdfService";

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

class Section {
  constructor(entries, beginDate, endDate, id) {
    this.id = id;
    this.entries = entries;
    if (beginDate) {
      this.beginDate = new Date(beginDate);
      this.dirtyBeginDate = new Date(beginDate);
    }
    if (beginDate) {
      this.endDate = new Date(endDate);
      this.dirtyEndDate = new Date(endDate);
    }
  }
}

class Entry {
  constructor(headline, value, model, options, className, translateHeadline) {
    this.headline = new Value(headline);
    this.value = new Value(value);
    this.model = new Value(model);
    this.options = options;
    this.input = "";
    this.className = className || "skill";
    this.uuid = uuidv4();
    this.translateHeadline = translateHeadline;
    this.page = 0;
  }
}

class ProfileEntry {
  constructor(key, value) {
    this.key = key;
    this.value = value;
  }
}

class Resetter {
  constructor() {
    this.values = [];
  }

  register(value) {
    this.values.push(value);
  }

  reset() {
    for (let value of this.values) {
      value.reset();
    }
  }
}

const $RESETTER = new Resetter();

class Value {
  constructor(value) {
    this.origin = value;
    if (Array.isArray(value)) {
      this.dirtyValue = value.map((x) => Object.assign({}, x));
    } else {
      this.dirtyValue = value;
    }
    $RESETTER.register(this);
  }

  reset() {
    if (Array.isArray(this.origin)) {
      this.dirtyValue = this.origin.map((x) => Object.assign({}, x));
    } else {
      this.dirtyValue = this.origin;
    }
  }
}

export default {
  name: "Profile",
  components: {
    AITextOptimizer,
  },
  data() {
    return {
      userId: null,
      employee: {
        profile: {},
      },
      employee_graduation: new Value(),
      employee_section: new Value(),
      profile: [],
      profileSectionList: [],
      entries: new Map(),
      renderKey: 0,
      page: 0,
      maxPage: 0,
      pages: [],
      skillGroups: [],
      profileEntries: [],
      editMode: false,
      allSkills: [],
      company: {},
      certificatesOrigin: null,
    };
  },
  beforeMount() {
    this.initAll();
  },
  mounted() {
    this.userId = this.$route.params.userId;
  },
  showError(error) {
    this.$toast.add({
      severity: "error",
      summary: this.$t("overviewofemployee.error"),
      detail: error.response ? error.response.data : error.message,
      life: 5000,
    });
  },
  updated() {
    this.resort();
  },
  methods: {
    initAll() {
      ProfileService.getAllSkills()
        .then((response) => {
          this.allSkills = response.data.allSkills;
          this.company = response.data.company;
        })
        .catch((error) => {
          this.toggleDialogError(
            error.response ? error.response.data : error.message
          );
        });

      // this should be replaced with an await call
      setTimeout(this.getEmployeeInformation, 1000);
    },
    getEmployeeInformation() {
      EmployeeService.getEmployee(this.userId)
        .then((response) => {
          this.initMap(response.data);
        })
        .catch((error) => {
          this.toggleDialogError(
            error.response ? error.response.data : error.message
          );
        });
    },
    optimizeProfileDescription(aiText, uuid) {
      const [sectionIndex, entryIndex] = this.getEntryIndexes(uuid);
      this.profileSectionList[sectionIndex][1].entries[
        entryIndex
      ].value.dirtyValue = aiText;
    },
    optimizeProjectTitle(aiText, uuid) {
      const [sectionIndex, entryIndex] = this.getEntryIndexes(uuid);

      this.profileSectionList[sectionIndex][1].entries[
        entryIndex
      ].headline.dirtyValue = aiText;
    },
    optimizeProjectDescItem(aiText, uuid, index) {
      const [sectionIndex, entryIndex] = this.getEntryIndexes(uuid);

      this.profileSectionList[sectionIndex][1].entries[
        entryIndex
      ].model.dirtyValue[index].value = aiText;
    },
    getEntryIndexes(entryUUID) {
      let sectionIndex,
        entryIndex = -1;
      for (let i = 0; i < this.profileSectionList.length; i++) {
        const j = this.profileSectionList[i][1].entries.findIndex(
          (item) => item.uuid === entryUUID
        );
        if (j >= 0) {
          entryIndex = j;
          sectionIndex = i;
          break;
        }
      }

      return [sectionIndex, entryIndex];
    },
    initMap(employee) {
      if (!employee.profile) {
        employee.profile = {
          description: "Beschreibung",
          skillTypes: this.allSkills.reduce(
            (accu, el) => accu.add(el.type),
            new Set()
          ),
          entries: [],
          skills: [],
        };
      }
      this.employee = employee;
      this.employee_graduation = new Value(employee.profile.graduation);
      this.employee_section = new Value(employee.profile.section);
      this.profile = [];
      this.profile.push(
        new ProfileEntry(
          "PROFIL",
          new Section([
            new Entry(
              null,
              employee.profile.description ? employee.profile.description : "",
              employee.profile.description
            ),
          ])
        )
      );
      let skills = [];
      for (let skillType of employee.profile.skillTypes) {
        const model = employee.profile.skills.filter(
          (skill) => skill.type === skillType
        );
        const options = this.allSkills.filter(
          (skill) => skill.type === skillType
        );
        skills.push(
          new Entry(
            skillType,
            model.map((skill) => skill.name).join(", "),
            model,
            options,
            "skill",
            true
          )
        );
      }
      let sortedSkills = [];
      sortedSkills.push(this.getEntryFromSkillList("CODING_LANGUAGE", skills));
      sortedSkills.push(this.getEntryFromSkillList("DATABASE", skills));
      sortedSkills.push(this.getEntryFromSkillList("FRAMEWORK", skills));
      sortedSkills.push(this.getEntryFromSkillList("QA", skills));
      sortedSkills.push(this.getEntryFromSkillList("TOOL", skills));
      this.profile.push(new ProfileEntry("SKILLS", new Section(sortedSkills)));
      if (employee.profile.certificates != null) {
        this.certificatesOrigin = employee.profile.certificates;
        this.profile.push(
          new ProfileEntry(
            "ZERTIFIKATE",
            new Section(
              this.certificatesOrigin.map((title) => new Entry(null, title))
            )
          )
        );
      }
      this.profile.push(
        new ProfileEntry("LANGUAGES", new Section([new Entry(null, "")]))
      );

      this.profile.push(
        new ProfileEntry("PROJEKTAUSZUG", new Section([new Entry()]))
      );
      for (let profileEntry of employee.profile.entries
        .sort((profileEntry1, profileEntry2) =>
          profileEntry1.endDate.localeCompare(profileEntry2.endDate)
        )
        .reverse()) {
        let entryDetails = [];
        for (let detail of profileEntry.details) {
          let redoneDetail = {
            id: detail.id,
            name: detail.value,
            type: detail.type,
          };
          entryDetails.push(redoneDetail);
        }
        profileEntry.details = entryDetails;
        let entries = [];
        entries.push(
          new Entry(
            profileEntry.description,
            profileEntry.descriptionDetails,
            profileEntry.descriptionDetails
          )
        );
        entries.push(
          new Entry(
            "STACK",
            profileEntry.stackAsString,
            profileEntry.stack,
            this.allSkills,
            "skill",
            true
          )
        );
        const sectorOptions = this.allSkills.filter(
          (skill) => skill.type === "SECTOR"
        );
        entries.push(
          new Entry(
            "SECTOR",
            profileEntry.sectors,
            profileEntry.details.filter((detail) => detail.type === "SECTOR"),
            sectorOptions,
            "skill",
            true
          )
        );
        const roleOptions = this.allSkills.filter(
          (skill) => skill.type === "ROLE"
        );
        entries.push(
          new Entry(
            "ROLE",
            profileEntry.roles,
            profileEntry.details.filter((detail) => detail.type === "ROLE"),
            roleOptions,
            "skill",
            true
          )
        );
        const methodOptions = this.allSkills.filter(
          (skill) => skill.type === "METHOD"
        );
        entries.push(
          new Entry(
            "METHOD",
            profileEntry.methods,
            profileEntry.details.filter((detail) => detail.type === "METHOD"),
            methodOptions,
            "skill",
            true
          )
        );
        this.profile.push(
          new ProfileEntry(
            profileEntry.period,
            new Section(
              entries,
              profileEntry.beginDate,
              profileEntry.endDate,
              profileEntry.id
            )
          )
        );
      }
      const sectionsList = [];
      for (const element of this.profile) {
        sectionsList.push(element.value);
      }
      for (let section of sectionsList) {
        for (let entry of section.entries) {
          this.entries.set(entry.uuid, entry);
        }
      }
      this.profileSectionList = Array.from(this.profile);
    },
    getEntryFromSkillList(skillType, skills) {
      for (let skill of skills) {
        if (skill.headline.origin === skillType) {
          return skill;
        }
      }
    },
    isProfileEntry(section) {
      const preProfileEntries = [
        "PROFIL",
        "SKILLS",
        "PROJEKTAUSZUG",
        "ZERTIFIKATE",
        "LANGUAGES",
      ];
      return !preProfileEntries.includes(section);
    },
    setMaxPage() {
      if (!this.entries.size) {
        this.maxPage = 0;
      }
      this.maxPage = Array.from(this.entries.values())
        .map((entry) => entry.page)
        .reduce((accu, page) => (accu > page ? accu : page));
    },
    resort() {
      const padding = 20;
      const sections = document.querySelectorAll("[data-id]");
      const footerHeight = document.getElementById("footer").clientHeight;
      const bodyHeight = document.getElementById("body").clientHeight - padding;
      const maxHeight = bodyHeight - footerHeight;

      for (let dom_section of sections) {
        if (dom_section.offsetTop + dom_section.clientHeight > maxHeight) {
          const correspondingSection = this.entries.get(dom_section.dataset.id);
          if (correspondingSection !== undefined) {
            correspondingSection.page++;
          }

          // Sektionen, die oberhalb von 2/3 der Seitenlänge beginnen und überstehen, aufsplitten
          if (dom_section.offsetTop < (maxHeight * 2) / 3) {
            //EMPTY
          }
          this.setMaxPage();
        }
      }
    },
    async exportPDF() {
      await PdfService.exportPDF(this.userId);
    },
    async exportWORD() {
      await PdfService.exportWORD(this.userId);
    },
    save() {
      const profileForm = {
        description: "",
        employee: this.employee.id,
        graduation: this.employee_graduation.dirtyValue,
        section: this.employee_section.dirtyValue,
        skills: [],
        entries: [],
        certificates: [],
      };
      for (const element of this.profile) {
        if (
          element.key === "PROFIL" &&
          element.value.entries &&
          element.value.entries.length
        ) {
          profileForm.description = element.value.entries[0].value.dirtyValue;
          continue;
        }

        if (element.key === "SKILLS") {
          profileForm.skills = profileForm.skills.concat(
            element.value.entries
              .map((entry) => entry.model.dirtyValue)
              .reduce((accu, el) => accu.concat(el), [], [])
          );
          continue;
        }

        if (element.key === "PROJEKTAUSZUG") {
          continue;
        }
        if (element.key === "ZERTIFIKATE") {
          for (let i = 0; i < element.value.entries.length; i++) {
            profileForm.certificates.push(
              element.value.entries[i].value.dirtyValue
            );
          }
          continue;
        }
        const profileEntry = {
          id: element.value.id,
          details: [],
          stack: [],
          beginDate: element.value.dirtyBeginDate,
          endDate: element.value.dirtyEndDate,
        };

        for (let entry of element.value.entries) {
          if (entry.headline.origin === "STACK") {
            profileEntry.stack = entry.model.dirtyValue;
            continue;
          }
          if (["SECTOR", "METHOD", "ROLE"].includes(entry.headline.origin)) {
            profileEntry.details = profileEntry.details.concat(
              entry.model.dirtyValue
            );
            continue;
          }
          profileEntry.description = entry.headline.dirtyValue;
          profileEntry.details = profileEntry.details.concat(
            entry.model.dirtyValue
          );
        }
        profileForm.entries.push(profileEntry);
      }
      for (let profileEntry of profileForm.entries) {
        let redoneDetails = [];
        for (let detailsEntry of profileEntry.details) {
          let nameValue = "";
          if (detailsEntry.name !== undefined) {
            nameValue = detailsEntry.name;
          } else {
            nameValue = detailsEntry.value;
          }
          const newEntry = {
            id: detailsEntry.id,
            value: nameValue,
            type: detailsEntry.type,
          };
          redoneDetails.push(newEntry);
        }
        profileEntry.details = redoneDetails;
      }
      ProfileService.updateProfile(profileForm)
        .then((response) => {
          this.page = 0;
          this.initMap(response.data);
        })
        .catch((error) =>
          this.toggleDialogError(
            error.response ? error.response.data : error.message
          )
        );
      this.editMode = false;
    },
    reset() {
      $RESETTER.reset();
      this.editMode = false;
      this.resetCertificates();
    },
    addNewValueToModel(event, sub) {
      let newValue = {
        id: this.allSkills.length + 1,
        name: event.target.value,
        type: sub.headline.dirtyValue,
      };
      this.allSkills.push(newValue);
      sub.options.push(newValue);
      event.target.value = "";
      EmployeeService.createSkill(newValue);
    },
    addProfileEntry() {
      const sectionList = this.profileSectionList;
      const newIndex =
        sectionList.indexOf(
          sectionList.filter((element) => element.key === "PROJEKTAUSZUG")[0]
        ) + 1;
      const now = new Date();
      const period = `${now.getMonth() + 1}/${now.getFullYear()} - ${
        now.getMonth() + 1
      }/${now.getFullYear()}`;
      const entries = [];
      const projectDescription = "Projektbeschreibung";
      for (let item of [
        projectDescription,
        "STACK",
        "METHOD",
        "ROLE",
        "SECTOR",
      ]) {
        const value = item === projectDescription ? [] : "";
        const translateHeadline = item !== projectDescription;
        const entry = new Entry(
          item,
          value,
          [],
          this.filterSkills(this.allSkills, item),
          item === "STACK" ? "skill" : "profileStorage",
          translateHeadline
        );
        entry.page = this.page;
        entries.push(entry);
        this.entries.set(entry.uuid, entry);
      }
      const newSection = new Section(entries, now, now);
      const entry = new ProfileEntry(period, newSection);
      sectionList.splice(newIndex, 0, entry);
      const profileEntry = new ProfileEntry(period, newSection);
      this.profile.push(profileEntry);
      this.renderKey++;
    },
    filterSkills(skills, skillType) {
      if (skillType === "Projektbeschreibung") {
        return;
      }
      if (skillType === "STACK") {
        return skills;
      }
      let resultArray = [];
      for (let item of skills) {
        if (item.type === skillType) {
          resultArray.push(item);
        }
      }
      return resultArray;
    },
    addDescription(model) {
      const value = this.$refs.descriptionInput[0].$el.value;
      model.push({ id: null, value: value, type: "DESCRIPTION" });
    },
    deleteDescription(model, index) {
      model.splice(index, 1);
    },
    switchPage(offset) {
      this.page = Math.max(0, this.page + offset);
    },
    toggleDialogError(msg) {
      this.$toast.add({
        severity: "error",
        summary: this.$t("skills.error"),
        detail: msg || this.$t("timeTracking.msgError"),
        life: 8000,
      });
    },
    deleteCertificate(entry) {
      for (let i = 0; i < this.profileSectionList.length; i++) {
        if (this.profileSectionList[i][0] === "ZERTIFIKATE") {
          const index = this.profileSectionList[i][1].entries.indexOf(entry);
          this.profileSectionList[i][1].entries.splice(index, 1);
        }
      }
    },
    resetCertificates() {
      for (let i = 0; i < this.profileSectionList.length; i++) {
        if (this.profileSectionList[i][0] === "ZERTIFIKATE") {
          this.profileSectionList[i][1].entries = this.certificatesOrigin.map(
            (title) => new Entry(null, title)
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.doc {
  width: 820px;
  height: 1150px;
  background-color: white;
  font-size: 14pt;
  position: relative;
}

.doc-header {
  padding-top: 70px;
  padding-left: 90px;
  width: 100%;
  height: 200px;
  background-color: #4475a1;
  position: relative;
}

.actionbar {
  position: absolute;
  top: 10px;
  z-index: 1;
  width: 100%;
  color: #fff;
}

.doc-header.small {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: initial;
  width: 100%;
  height: 60px;
  background-color: #4475a1;
  position: relative;
  color: #fff;
}

.doc-header > img {
  position: absolute;
  left: 570px;
  top: 60px;
  width: 190px;
  background-color: #fff;
  border: 1px solid black;
}

.doc-header:not(.small) > .person {
  width: 300px;
  height: 110px;
  color: #fff;
}

.blue-emphasis {
  content: " ";
  background-color: #053d71;
  height: 28px;
  width: 100%;
}

.bottom {
  position: absolute;
  bottom: 0;
}

.body {
  padding-top: 20px;
  color: #3b3838;
  position: relative;
  height: 928px;
}

.divider {
  position: absolute;
  width: 1px;
  height: 800px;
  left: 252px;
  border: 2px solid #4475a1;
  border-radius: 4px;
}

.large > .divider {
  height: 930px;
}

.light-blue-footer {
  background-color: #4475a1;
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 28px;
  color: #fff;
  font-size: 11px;
}

.page {
  display: flex;
  align-items: center;
  padding-right: 10px;
  color: #fff;
  font-size: 11pt;
  justify-content: flex-end;
}

.page > span {
  border: 1px solid #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 9px;
  align-items: center;
}

h5 {
  font-size: 14pt;
}
</style>
