<template>
  <Form id="msform">
    <div class="p-grid px-4">
      <!-- contactEmail -->
      <div class="p-col-3 p-align-center p-d-flex">
        <label class="required">{{ $t("client.contact-email") }}</label>
      </div>
      <div class="p-col-9">
        <Field
          v-model="state.formValue.contactEmail"
          name="contactEmail"
          as="input"
          :model-value="state.formValue.contactEmail"
          class="p-inputtext p-component w-12 bg-grey"
          :value="state.formValue.contactEmail"
          @change="v$.state.formValue.contactEmail.$touch()"
        />
        <small v-if="v$.state.formValue.contactEmail.$error" class="error">
          {{ v$.state.formValue.contactEmail.$errors[0].$message }}</small
        >
      </div>

      <!-- invoiceEmail -->
      <div class="p-col-3 p-align-center p-d-flex">
        <label class="required">{{ $t("client.invoice-email") }}</label>
      </div>
      <div class="p-col-9">
        <Field
          v-model="state.formValue.invoiceEmail"
          name="invoiceEmail"
          as="input"
          :model-value="state.formValue.invoiceEmail"
          class="p-inputtext p-component w-12 bg-grey"
          :value="state.formValue.invoiceEmail"
          @change="v$.state.formValue.invoiceEmail.$touch()"
        />
        <small v-if="v$.state.formValue.invoiceEmail.$error" class="error">
          {{ v$.state.formValue.invoiceEmail.$errors[0].$message }}</small
        >
      </div>

      <!-- phoneNumber -->
      <div class="p-col-3 p-align-center p-d-flex">
        <label class="required">{{ $t("client.phone-number") }}</label>
      </div>
      <div class="p-col-9">
        <Field
          v-model="state.formValue.phoneNumber"
          name="phoneNumber"
          as="input"
          :model-value="state.formValue.phoneNumber"
          class="p-inputtext p-component w-12 bg-grey"
          :value="state.formValue.phoneNumber"
          aria-required="true"
          @change="v$.state.formValue.phoneNumber.$touch()"
        />
        <small v-if="v$.state.formValue.phoneNumber.$error" class="error">
          {{ v$.state.formValue.phoneNumber.$errors[0].$message }}</small
        >
      </div>

      <!-- contactPerson -->
      <div class="p-col-3 p-align-center p-d-flex">
        <label class="required">{{ $t("client.contact-person") }}</label>
      </div>
      <div class="p-col-9">
        <Field
          v-model="state.formValue.contactPerson"
          name="contactPerson"
          as="input"
          :model-value="state.formValue.contactPerson"
          class="p-inputtext p-component w-12 bg-grey"
          :value="state.formValue.contactPerson"
          @change="v$.state.formValue.contactPerson.$touch()"
        />
        <small v-if="v$.state.formValue.contactPerson.$error" class="error">
          {{ v$.state.formValue.contactPerson.$errors[0].$message }}</small
        >
      </div>

      <!-- name -->
      <div class="p-col-3 p-align-center p-d-flex">
        <label class="required">{{ $t("client.name") }}</label>
      </div>
      <div class="p-col-9">
        <Field
          v-model="state.formValue.name"
          name="name"
          as="input"
          :model-value="state.formValue.name"
          class="p-inputtext p-component w-12 bg-grey"
          :value="state.formValue.name"
          @change="v$.state.formValue.name.$touch()"
        />
        <small v-if="v$.state.formValue.name.$error" class="error">
          {{ v$.state.formValue.name.$errors[0].$message }}</small
        >
      </div>
    </div>
  </Form>
</template>
<script>
import { Field, Form } from "vee-validate";
import global from "./newClient";
import useVuelidate from "@vuelidate/core";
import { email, helpers, required } from "@vuelidate/validators";

export default {
  name: "AddClientBasicInfo",
  components: {
    Form,
    Field,
  },

  setup() {
    const { state } = global;
    return {
      state,
      v$: useVuelidate(),
    };
  },

  data() {
    return {};
  },

  validations() {
    return {
      state: {
        formValue: {
          contactEmail: {
            required: helpers.withMessage(
              this.$t("client.errorEmptyEmail"),
              required
            ),
            test: helpers.withMessage(this.$t("employee.invalidEmail"), email),
          },
          invoiceEmail: {
            required: helpers.withMessage(
              this.$t("client.errorEmptyInvoiceEmail"),
              required
            ),
            test: helpers.withMessage(this.$t("employee.invalidEmail"), email),
          },
          phoneNumber: {
            required: helpers.withMessage(
              this.$t("client.errorEmptyPhone"),
              required
            ),
            test: helpers.withMessage(
              this.$t("employee.invalidPhone"),
              helpers.regex(/^[0-9+\s]+$/)
            ),
          },
          contactPerson: {
            required: helpers.withMessage(
              this.$t("client.errorEmptyContactPerson"),
              required
            ),
            test: helpers.withMessage(
              this.$t("employee.onlyLetters"),
              helpers.regex(/^[A-Za-zŽžÀ-ÿ-.\s]+$/)
            ),
          },
          name: {
            required: helpers.withMessage(
              this.$t("client.errorEmptyName"),
              required
            ),
          },
        },
      },
    };
  },

  methods: {},
};
</script>

<style lang="scss">
label {
  color: white;
}

button {
  border: 0;
  padding: 10px 20px;
  margin-top: 20px;
  border-right: 20px;
}

select {
  display: flex;
  position: relative;
  width: 100%;
  height: 47px;
  line-height: 30px;
}

h4 {
  text-align: start;
  font-family: montserrat, sans-serif;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  color: white;
}

.submit {
  text-align: center;
}

span {
  display: block;
}

.grid-container-commissioning2 {
  display: grid;
  grid-template-columns: 75% 15% 5% 5%;
  padding-top: 0;
  text-align: left;
}

.grayOut {
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 992px) {
  .h-lg-50px {
    height: 50.4px;
  }
}

.error {
  color: coral;
}
</style>
