import {RequestClient} from "@/service/RequestClient";

const apiClient = new RequestClient("widgets");

export default {
    getEvents() {
        return apiClient.get(`/list`);
    },

    getEvent(id) {
        return apiClient.get(`/${id}`);
    },

    postButton(name, iconName, address) {
        return apiClient.post(`/create`, {
            name: name,
            iconName: iconName,
            url: address,
        });
    },

    deleteButton(id) {
        return apiClient.delete(`/delete/${id}`);
    },
};
