import {reactive} from "vue";
import {msalInstance} from "@/authentication/authConfig";

export const Profile = reactive({
    username: "",
    id: "",
    secret: "",
    group: "",

    getGraphData() {
        const res = msalInstance.getActiveAccount();
        if (res) {
          this.username = res.username;
          this.id = res.localAccountId;
        }
    },
    getId() {
        if (!this.id) {
            this.getGraphData();
        }
        return this.id;
    },
    getGroup() {
        return this.group;
    }
});
