import {RequestClient} from "@/service/RequestClient";

const apiClient = new RequestClient("ai", null, "text/plain", 50000)

export default {
    optimizeText(text) {
        return apiClient.post(`/optimize-text`, text);
    },

    optimizeTitle(titleTheme, titel) {
        return apiClient.post(
            `/optimize-title/${titleTheme}`,
            titel
        );
    },
};
