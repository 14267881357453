<template>
  <div class="exception-body error">
    <div class="exception-wrapper">
      <div class="exception-content">
        <div style="width: 100%; justify-content: center; align-content: center; display: flex">
          <div style="width: 40%">
            <img
              alt=""
              loading="eager"
              src="assets/layout/images/pages/asset-error.svg"
            />
          </div>
        </div>
        <span style="color: white">Error {{ error }}</span
        ><br />
        <span style="color: white; font-size: 15px;">{{ $t("errorPage.errorText") }}</span
        ><br />
        <span style="color: white; font-size: small">{{ id }}</span>
        <div style="display: flex; justify-content: center">
          <div>
            <Button label="Retry" type="button" @click="retry"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserRights from "@/authentication/UserRights";
import { msalInstance } from "@/authentication/authConfig";

export default {
  data() {
    return {
      error: UserRights.getError(),
      id: UserRights.getUserId(),
    };
  },

  computed: {
    colorScheme() {
      return this.$appState.colorScheme;
    }
  },
  methods: {
    retry() {
      msalInstance.logoutRedirect();
      UserRights.logOut();
    }
  }
};
</script>
