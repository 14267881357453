import { RequestClient } from "@/service/RequestClient";

const apiClient = new RequestClient("employees");

export default {
  getEmployees() {
    return apiClient.get(`/list`);
  },

  getEmployee(id) {
    return apiClient.get(`/employee/${id}`);
  },

  getEmployeeGroup() {
    return apiClient.get(`/group`);
  },

  updateTerminateEmployee(employee) {
    return apiClient.put(`/saveTerminateDate/${employee}`);
  },
  create(form) {
    return apiClient.post(`/create`, form);
  },

  createSkill(skill) {
    return apiClient.post(`/profile/createSkill`, skill);
  },

  changeAndUpdateEmployee(employee) {
    return apiClient.put(`/compareAndUpdate`, employee);
  },

  updateEmployee(id, employee) {
    return apiClient.put(`/update/${id}`, employee);
  },

  checkAvailableEmail(mail) {
    return apiClient.get(`/isAvailableEmail/${mail}`);
  },

  updateBankAccount(id, bankAccount) {
    return apiClient.put(`/updateBank/${id}`, bankAccount);
  },

  getEmployeesForBirthdayCalendar() {
    return apiClient.get(`/employeesForBirthdayCalendar`);
  },
};
