import {RequestClient} from "@/service/RequestClient";

const pdfClient = new RequestClient("profile", "blob", "application/pdf");
const wordClient = new RequestClient("profile", "blob", "application/msword");

export default {
    async exportPDF(userId) {
        pdfClient.get(`/exportProfile/${userId}`)
            .then(response => {
                window.open(window.URL.createObjectURL(response.data), "_blank");
            }).catch(e => console.error("Fehler beim Exportieren des Profils als PDF", e));
    },

    async exportWORD(userId) {
        wordClient.get(`/exportProfileWord/${userId}`)
            .then(response => {
                window.open(window.URL.createObjectURL(response.data), "_blank");
            }).catch(e => console.error("Fehler beim Exportieren des Profils als Word-Dokument", e));
    },
};