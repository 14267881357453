import {InteractionRequiredAuthError} from "@azure/msal-browser";
import {loginRequest, msalInstance} from "@/authentication/authConfig";
import {Buffer} from "buffer";
import router from "@/router";

export class TokenService {
    static JWT_KEY = "jwt";

    static async getValidJwt() {
        const currentJwt = this.readJwt();

        if (!currentJwt || (currentJwt.exp * 1000) < new Date().getTime()) {
            try {
                const response = await msalInstance.acquireTokenSilent(loginRequest);
                sessionStorage.setItem(this.JWT_KEY, response.idToken);
                if (this.readJwt().exp * 1000 < new Date()) {
                    // redirect to login page, any following code is not executed
                    msalInstance.acquireTokenRedirect(loginRequest);
                }
            } catch (error) {
                console.log("Fehler beim Token erneuern", error)
                if (error instanceof InteractionRequiredAuthError) {
                    // redirect to login page, any following code is not executed
                    msalInstance.acquireTokenRedirect(loginRequest);
                } else {
                    await router.push({path: "/Error"});
                }
            }
        }
        return sessionStorage.getItem(this.JWT_KEY);
    }

    static readJwt() {
        const encodedJwt = sessionStorage.getItem(this.JWT_KEY);
        return encodedJwt ? JSON.parse(Buffer.from(encodedJwt.split('.')[1], 'base64').toString()) : null;
    }
}