import { reactive } from "vue";

class Address {
  constructor(source) {
    this.street = source ? source.street : "";
    this.number = source ? source.number : "";
    this.zipCode = source ? source.zipCode : "";
    this.city = source ? source.city : "";
  }
}

class FormValue {
  constructor() {
    this.id = null;
    this.contactEmail = "";
    this.invoiceEmail = "";
    this.phoneNumber = "";
    this.contactPerson = "";
    this.name = "";
    this.address = new Address();
    this.billingAddress = new Address();
  }

  reset() {
    for (let prop in this) {
      if (this[prop] instanceof Address) {
        this[prop] = new Address();
      }
      if (typeof this[prop] === "string" || this[prop] instanceof String) {
        this[prop] = "";
      }
    }
  }

  update(source) {
    for (let prop in this) {
      if (
        // https://stackoverflow.com/questions/39282873/object-hasownproperty-yields-the-eslint-no-prototype-builtins-error-how-to
        // eslint-disable-next-line no-prototype-builtins
        !source.hasOwnProperty(prop) ||
        (source[prop] &&
          this[prop] &&
          typeof source[prop] !== typeof this[prop])
      ) {
        continue;
      }

      if (this[prop] instanceof Address) {
        this[prop] = new Address(source[prop]);
        continue;
      }

      this[prop] = source[prop];
    }
  }
}

const state = reactive({
  formValue: new FormValue(),
});
export default {
  state,
};
