import {reactive} from "vue";

export const Store = reactive({
    employeeOriginal: {},
    employeeUpdated: {
        address: {},
    },
    isOrderHardware: false,
    orderHardwareId: "",
    employeeID: "",
    profileID: "",
    userGroup: 0,
    userGroupType: "",
    isLoggedIn: false,
    userEmail: "",
    userName: "",
    userId: "",
    error: "",
});
