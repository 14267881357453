import {Store} from "@/service/Store";
import {Profile} from "@/authentication/Profile";

const userType = ["Employee", "Office", "Leader", "Admin"];

export default {
    EMPLOYEE: 0,
    OFFICE: 1,
    LEADER: 2,
    ADMIN: 3,

    getUserEmail() {
        this.readLocalStorage();
        return Store.userEmail;
    },

    getUserName() {
        this.readLocalStorage();
        return Store.userName;
    },

    getUserId() {
        return sessionStorage.getItem("userid");
    },

    getProfileUser() {
        return Store.profileID;
    },
    getUserGroup() {
        return Profile.getGroup();
    },
    isOrderHardware() {
        this.readLocalStorage();
        return Store.isOrderHardware;
    },
    getHardwareOrderId() {
        this.readLocalStorage();
        return Store.orderHardwareId;
    },

    setCypressProfile() {
        Store.isLoggedIn = true;
        Store.userEmail = "cypressuser@4brainsolutions.com";
        Store.userName = "Cypress User";
        Store.userId = "fghj";
        Store.error = "";
        Store.userGroup = this.ADMIN;
        Store.employeeID = "cypressUser-58s147dz95e";
        Store.profileID = Store.employeeID;
        Store.userGroupType = userType[Store.userGroup];
        this.writeLocalStorage();
    },
    setOrderHardware() {
        this.readLocalStorage();
        if (!Store.isOrderHardware) {
            Store.isLoggedIn = false;
            Store.isOrderHardware = true;
            this.writeLocalStorage();
        }
    },
    setOrderHardwareId(id) {
        if (id.length > 0) {
            Store.orderHardwareId = id;
            this.writeLocalStorage();
        } else this.readLocalStorage();
    },

    readLocalStorage() {
    },

    writeLocalStorage() {
    },

    getError() {
        this.readLocalStorage();
        return Store.error;
    },

    isLoggedIn() {
        this.readLocalStorage();
        return Store.isLoggedIn;
    },

    setLoggedIn() {
        Store.isLoggedIn = true;
        this.writeLocalStorage();
    },

    logOut() {
        sessionStorage.clear();
    },
    isUserGroup(group) {
        return this.getUserGroup() >= group;
    },
    strToUserGroup(group) {
        if (group === "EMPLOYEE") return this.EMPLOYEE;
        if (group === "OFFICE") return this.OFFICE;
        if (group === "LEADER") return this.LEADER;
        if (group === "ADMIN") return this.ADMIN;
    },
};
