<template>
  <Form id="msform">
    <div class="p-grid px-4">
      <!-- city -->
        <Button
            class="w-12"
            :label="$t('client.takeClientAddress')"
            @click="copyAddress()"
        />
      <br/>
      <br/>
      <div class="p-col-3 p-align-center p-d-flex">
        <label class="required">{{ $t("client.city") }}</label>
      </div>
      <div class="p-col-9">
        <Field
          v-model="state.formValue.billingAddress.city"
          name="city"
          as="input"
          :model-value="state.formValue.billingAddress.city"
          class="p-inputtext p-component w-12 bg-grey"
          :value="state.formValue.billingAddress.city"
          @change="v$.state.formValue.billingAddress.city.$touch()"
        />
        <small
          v-if="v$.state.formValue.billingAddress.city.$error"
          class="error"
        >
          {{
            v$.state.formValue.billingAddress.city.$errors[0].$message
          }}</small
        >
      </div>

      <!-- zipCode -->
      <div class="p-col-3 p-align-center p-d-flex">
        <label class="required">{{ $t("client.zip-code") }}</label>
      </div>
      <div class="p-col-9">
        <Field
          v-model="state.formValue.billingAddress.zipCode"
          name="zipCode"
          as="input"
          :model-value="state.formValue.billingAddress.zipCode"
          class="p-inputtext p-component w-12 bg-grey"
          :value="state.formValue.billingAddress.zipCode"
          @change="v$.state.formValue.billingAddress.zipCode.$touch()"
        />
        <small
          v-if="v$.state.formValue.billingAddress.zipCode.$error"
          class="error"
        >
          {{
            v$.state.formValue.billingAddress.zipCode.$errors[0].$message
          }}</small
        >
      </div>

      <!-- street -->
      <div class="p-col-3 p-align-center p-d-flex">
        <label class="required">{{ $t("client.street") }}</label>
      </div>
      <div class="p-col-9">
        <Field
          v-model="state.formValue.billingAddress.street"
          name="street"
          as="input"
          :model-value="state.formValue.billingAddress.street"
          class="p-inputtext p-component w-12 bg-grey"
          :value="state.formValue.billingAddress.street"
          @change="v$.state.formValue.billingAddress.street.$touch()"
        />
        <small
          v-if="v$.state.formValue.billingAddress.street.$error"
          class="error"
        >
          {{
            v$.state.formValue.billingAddress.street.$errors[0].$message
          }}</small
        >
      </div>

      <!-- number -->
      <div class="p-col-3 p-align-center p-d-flex">
        <label class="required">{{ $t("client.number") }}</label>
      </div>
      <div class="p-col-9">
        <Field
          v-model="state.formValue.billingAddress.number"
          name="number"
          as="input"
          :model-value="state.formValue.billingAddress.number"
          class="p-inputtext p-component w-12 bg-grey"
          :value="state.formValue.billingAddress.number"
          @change="v$.state.formValue.billingAddress.number.$touch()"
        />
        <small
          v-if="v$.state.formValue.billingAddress.number.$error"
          class="error"
        >
          {{
            v$.state.formValue.billingAddress.number.$errors[0].$message
          }}</small
        >
      </div>
    </div>
  </Form>
</template>
<script>
import { Field, Form } from "vee-validate";
import global from "./newClient";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
  name: "AddClientBillingAddress",
  components: {
    Form,
    Field,
  },

  setup() {
    const { state } = global;
    return {
      state,
      v$: useVuelidate(),
    };
  },

  data() {
    return {};
  },

  validations() {
    return {
      state: {
        formValue: {
          billingAddress: {
            street: {
              required: helpers.withMessage(
                this.$t("client.errorEmptyStreet"),
                required
              ),
              test: helpers.withMessage(
                this.$t("employee.onlyLetters"),
                helpers.regex(/^[A-Za-zŽžÀ-ÿ-.\s]+$/)
              ),
            },
            number: {
              required: helpers.withMessage(
                this.$t("client.errorEmptyHouseNumber"),
                required
              ),
              test: helpers.withMessage(
                this.$t("employee.invalidNumber"),
                helpers.regex(/^([0-9])+([A-Za-z]?)$/)
              ),
            },
            zipCode: {
              required: helpers.withMessage(
                this.$t("client.errorEmptyZipCode"),
                required
              ),
              test: helpers.withMessage(
                this.$t("employee.invalidZipCode"),
                helpers.regex(/^([0-9A-Z-\s]+$)/)
              ),
            },
            city: {
              required: helpers.withMessage(
                this.$t("client.errorEmptyCity"),
                required
              ),
              test: helpers.withMessage(
                this.$t("employee.onlyLetters"),
                helpers.regex(/^[A-Za-zŽžÀ-ÿ-.\s]+$/)
              ),
            },
          },
        },
      },
    };
  },

  methods: {
    copyAddress(){
      this.state.formValue.billingAddress = this.state.formValue.address;
    }
  },
};
</script>

<style lang="scss">
label {
  color: white;
}

button {
  border: 0;
  padding: 10px 20px;
  margin-top: 20px;
  border-right: 20px;
}

select {
  display: flex;
  position: relative;
  width: 100%;
  height: 47px;
  line-height: 30px;
}

h4 {
  text-align: start;
  font-family: montserrat, sans-serif;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  color: white;
}

.submit {
  text-align: center;
}

span {
  display: block;
}

.grid-container-commissioning2 {
  display: grid;
  grid-template-columns: 75% 15% 5% 5%;
  padding-top: 0;
  text-align: left;
}

.grayOut {
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 992px) {
  .h-lg-50px {
    height: 50.4px;
  }
}
</style>
