<template>
  <Breadcrumb :home="home" :model="items">
    <template #item="{ item }">
      <router-link
          v-slot="{ href, navigate, isActive, isExactActive }"
          :to="item.to"
          custom
      >
        <a
            :class="{
            'active-link': isActive,
            'active-link-exact': isExactActive,
          }"
            :href="sanitizeHref(href)"
            @click="navigate"
        >
          <span v-if="item && item.hasOwnProperty('icon')" :class="item.icon"/>
          <span v-else>{{ $t(item.label) }}</span>
        </a>
      </router-link>
    </template>
  </Breadcrumb>
</template>

<script>
import {defineComponent} from "vue";
import DOMPurify from "dompurify";

export default defineComponent({
  data() {
    return {
      home: {icon: "pi pi-home", url: "/", to: "/", label: "home"},
      items: [],
    };
  },

  created() {
    this.createBreadCrumbs();
  },
  methods: {
    createBreadCrumbs() {
      let item = this.$route.meta.breadcrumb;
      if (!item) return;
      item.to = this.$route.path;

      while (
          item &&
          !Array.isArray(item) &&
          !this.items.includes(item) &&
          item.to !== "/"
          ) {
        this.items.unshift(item);
        if (!item.parent) {
          return null;
        }
        item = this.genItem(item.parent);
      }
    },
    genItem(breadcrumb) {
      const parent = this.$router.options.routes.filter(
          (route) => route.name === breadcrumb
      );
      if (parent.length === 0) {
        return null;
      }
      const routeParent = parent[0];
      const item = routeParent.meta.breadcrumb;
      item.to = routeParent.path;
      return item;
    },
    sanitizeHref(href) {
      return DOMPurify.sanitize(href);
    },
  },
});
</script>
