<template>
  <div class="layout-topbar" style="background-color: #182534">
    <div class="layout-topbar-wrapper">
      <AppMenu
        :layout-mode="layoutMode"
        :sidebar-active="sidebarActive"
        :sidebar-static="sidebarStatic"
        :menu-active="menuActive"
        :mobile-menu-active="mobileMenuActive"
        @sidebar-mouse-leave="onSidebarMouseLeave"
        @sidebar-mouse-over="onSidebarMouseOver"
        @toggle-menu="onToggleMenu"
        @menu-click="onMenuClick"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
      />
    </div>
  </div>
</template>

<script>
import AppMenu from "./AppMenu";

export default {
  name: "AppTopbar",
  components: {
    AppMenu,
  },
  props: {
    searchActive: Boolean,
    searchClick: Boolean,
    topbarItemClick: Boolean,
    topbarUserMenuActive: Boolean,
    topbarUserMenuClick: Boolean,
    activeTopbarItem: {
      type: String,
      default: null,
    },
    sidebarActive: Boolean,
    sidebarStatic: Boolean,
    layoutMode: {
      type: String,
      default: null,
    },
    topbarTheme: {
      type: String,
      default: null,
    },
    menuActive: Boolean,
    mobileMenuActive: Boolean,
  },
  emits: [
    "menu-click",
    "menuitem-click",
    "root-menuitem-click",
    "menu-button-click",
    "toggle-menu",
    "right-menubutton-click",
    "sidebar-mouse-over",
    "sidebar-mouse-leave",
    "topbar-search-toggle",
    "topbar-search-click",
    "topbar-search-hide",
    "topbar-usermenu-click",
    "update:searchClick",
  ],
  unmounted() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  },
  methods: {
    onMenuClick(event) {
      this.$emit("menu-click", event);
    },
    onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
    },
    onRootMenuItemClick(event) {
      this.$emit("root-menuitem-click", event);
    },
    onMenuButtonClick(event) {
      this.$emit("menu-button-click", event);
    },
    onToggleMenu(event) {
      this.$emit("toggle-menu", event);
    },
    onTopbarSearchToggle(event) {
      this.$emit("topbar-search-toggle", event);
      this.onSearchFocus();
    },
    onTopbarSearchClick(event) {
      this.$emit("topbar-search-click", event);
    },
    onInputKeydown(event) {
      const key = event.which;

      //escape, tab and enter
      if (key === 27 || key === 9 || key === 13) {
        this.$emit("topbar-search-hide", event);
      }
    },
    onTopbarUserMenuClick(event) {
      this.$emit("topbar-usermenu-click", event);
    },
    onRightMenuButtonClick(event) {
      this.$emit("right-menubutton-click", event);
    },
    onSidebarMouseOver() {
      this.$emit("sidebar-mouse-over");
    },
    onSidebarMouseLeave() {
      this.$emit("sidebar-mouse-leave");
    },
    onSearchFocus() {
      if (window.innerWidth >= 576) {
        this.$refs.desktopInput.$el.focus();
      } else {
        this.$nextTick(function () {
          this.$refs.phoneInput.$el.focus();
        });
      }
    },
  },
};
</script>
<style>
.layout-topbar-light .layout-topbar {
  height: 0;
}

h1 {
  font-weight: bold;
  text-align: center;
  color: white;
}
</style>
