<template>
  <div
    v-if="isAppMenu"
    class="menu-wrapper"
    :class="{ 'layout-sidebar-active': sidebarActive }"
    @click="onMenuClick"
    @mouseleave="onSidebarMouseLeave"
    @mouseover="onSidebarMouseOver"
  >
    <div class="sidebar-logo">
      <router-link to="/">
        <img alt="freya-layout" src="@/assets/tim_the_butler.png" />
      </router-link>
      <a class="sidebar-pin" @click="onToggleMenu($event)">
        <span class="pin"></span>
      </a>
    </div>

    <div class="layout-menu-container">
      <AppSubmenu
        class="layout-menu"
        :items="menu"
        :layout-mode="layoutMode"
        :parent-menu-item-active="true"
        :menu-active="menuActive"
        :mobile-menu-active="mobileMenuActive"
        :root="true"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
      />
    </div>
  </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";
import EventService from "@/service/WidgetService";
import UserRights from "@/authentication/UserRights";
import { msalInstance } from "./authentication/authConfig";

export default {
  name: "AppMenu",
  components: {
    AppSubmenu
  },
  props: {
    sidebarActive: Boolean,
    sidebarStatic: Boolean,
    layoutMode: {
      type: String,
      default: "default"
    },
    menuActive: Boolean,
    mobileMenuActive: Boolean
  },
  default: "sidebar",
  emits: [
    "menu-click",
    "menuitem-click",
    "root-menuitem-click",
    "toggle-menu",
    "sidebar-mouse-over",
    "sidebar-mouse-leave"
  ],
  data() {
    return {
      menu: [],
      userRights: UserRights,
      isAppMenu: !UserRights.isOrderHardware()
    };
  },
  beforeMount() {
    if (UserRights.getUserGroup() < 0) return;
    if (!this.checkforUniqueURL("https://start.4brainsolutions.com")) {

      if (UserRights.isUserGroup(UserRights.EMPLOYEE)) {
        this.menu.push({
          label: "Home", icon: "pi pi-home", url: "/#/"
        });
        this.menu.push({
          label: "Sharepoint",
          icon: "pi pi-folder",
          url: "https://start.4brainsolutions.com"
        });
        this.menu.push({
          label: "News",
          icon: "pi pi-align-center",
          url: "https://4brainsolutionscom.sharepoint.com/sites/news"
        });
        this.menu.push({
          label: "Instagram",
          icon: "pi pi-instagram",
          url: "http://instagram.com/4brainsolutions"
        });
        this.menu.push({
          label: "facebook",
          icon: "pi pi-facebook",
          url: "http://facebook.com/4brainsolutions"
        });
        this.menu.push({
          label: "linkedin",
          icon: "pi pi-linkedin",
          url: "http://linkedin.com/company/4brainsolutions-gmbh"
        });
        this.menu.push({
          label: "Youtube",
          icon: "pi pi-youtube",
          url: "http://youtube.com/@4BrainSolutions"
        });
        this.menu.push({
          label: "Support",
          icon: "pi pi-comments",
          url: "https://4brainsolutions.atlassian.net/jira/core/projects/TIMS/form/8?atlOrigin=eyJpIjoiZDg5ZWUwNWM3ZjU3NGEyNGI4YjQ4NTZjZWQ3YjI3NGEiLCJwIjoiaiJ9"
        });
      }
    }

    if (this.userRights.isLoggedIn()) {
      EventService.getEvents().then((response) => {
        for (const responseElement of response.data) {
          if (!this.checkforUniqueURL(responseElement.url)) {
            this.menu.push({
              label: responseElement.name,
              icon: responseElement.iconName,
              url: responseElement.url
            });
          }
        }
      });
    }

    if (
      !this.checkforUniqueURL("/CreateButtonForm") &&
      !this.checkforUniqueURL("/#/DeleteButtonForm") &&
      this.userRights.isLoggedIn()
    ) {
      if (UserRights.isUserGroup(UserRights.ADMIN))
        this.menu.push({
          label: "Erstelle Custom Widget",
          icon: "pi pi-plus",
          url: "/#/CreateButtonForm"
        });
      if (UserRights.isUserGroup(UserRights.ADMIN))
        this.menu.push({
          label: "Entferne Custom Widget",
          icon: "pi pi-minus",
          url: "/#/DeleteButtonForm"
        });
    }
  },
  methods: {
    checkforUniqueURL(itemURL) {
      let containsURL = false;
      for (const item of this.menu) {
        if (item.url === itemURL) {
          containsURL = true;
          break;
        }
      }
      return containsURL;
    },

    onSidebarMouseOver() {
      if (this.layoutMode === "sidebar" && !this.sidebarStatic) {
        this.$emit("sidebar-mouse-over");
      }
    },
    onSidebarMouseLeave() {
      if (this.layoutMode === "sidebar" && !this.sidebarStatic) {
        setTimeout(() => {
          this.$emit("sidebar-mouse-leave");
        }, 250);
      }
    },
    onToggleMenu(event) {
      this.$emit("toggle-menu", event);
    },
    onMenuClick(event) {
      this.$emit("menu-click", event);
    },
    onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
      if (event.item.label === "Erstelle Custom Widget") {
        this.addButton();
      } else if (event.item.label === "Entferne Custom Widget") {
        this.removeButton();
      } else if (event.item.label === "Abmelden") {
        UserRights.logOut();
        msalInstance.logoutRedirect();
      }
    },
    onRootMenuItemClick(event) {
      this.$emit("root-menuitem-click", event);
    },
    addButton() {
      this.$router.push({ name: "CreateButtonForm" });
    },
    removeButton() {
      this.$router.push({ name: "DeleteButtonForm" });
    }
  }
};
</script>
