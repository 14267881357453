<template>
  <div style="margin-bottom: 1rem">
    <button
      v-if="!compact"
      :disabled="isAiProcessing"
      @click.stop="optimize($event)"
    >
      {{ $t("profileOverview.optimize") }}
    </button>
    <i
      v-else
      class="pi pi-bolt"
      data-cy="deleteDetail"
      style="cursor: pointer"
      :title="$t('profileOverview.optimize')"
      @click.stop="optimize($event)"
    ></i>

    <OverlayPanel ref="overlayOptimize">
      <Textarea
        v-if="aiText.length > 0"
        v-model="aiText"
        :auto-resize="true"
        rows="5"
        cols="54"
        class="w-12"
      />

      <div v-if="isAiProcessing" class="mt-2 w-12 justify-content-center">
        <i
          class="justify-content-center pi pi-spin pi-spinner"
          style="font-size: 1rem"
        ></i>
        {{ $t("profileOverview.wait") }}
      </div>

      <div v-if="!isAiProcessing">
        <button
          class="mt-2 p-button w-6 justify-content-center"
          @click="optimizationAccepted"
        >
          {{ $t("profileOverview.accept") }}
        </button>
        <button class="mt-2 p-button w-6 justify-content-center" @click="close">
          {{ $t("MISC-Button.cancel") }}
        </button>
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
import AIService from "@/service/AIService";

export default {
  props: {
    originalText: {
      type: String,
      default: ''
    },
    uuid: {
      type: String,
      default: ''
    },
    section: {
      type: String,
      default: ''
    },
    compact: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
  },
  emits: ["accepted"],
  data() {
    return {
      aiText: "",
      isAiProcessing: false,
    };
  },
  computed: {
    isProjectDescItem() {
      return this.index !== null && this.index !== undefined;
    },
  },
  methods: {
    optimize(event) {
      this.isAiProcessing = true;
      this.$refs.overlayOptimize.show(event);
      if (this.section === "PROFIL") {
        if (this.isProjectDescItem)
          this.optimizeTitle(); // project description item
        else this.optimizeText(); // profile description
      } else {
        // project title
        this.optimizeTitle();
      }
    },
    optimizeText() {
      AIService.optimizeText(this.originalText)
        .then((response) => {
          this.isAiProcessing = false;
          this.aiText = response.data;
        })
        .catch((error) => this.handleError(error));
    },
    optimizeTitle() {
      const titleTheme = "Lebenslauf";
      AIService.optimizeTitle(titleTheme, this.originalText)
        .then((response) => {
          this.isAiProcessing = false;
          this.aiText = response.data;
        })
        .catch((error) => this.handleError(error));
    },
    handleError(error) {
      console.error(error);
      this.isAiProcessing = false;
      this.toggleDialogError(
        error.response ? error.response.data : error.message
      );
    },
    optimizationAccepted() {
      if (this.isProjectDescItem)
        this.$emit("accepted", this.aiText, this.uuid, this.index);
      else this.$emit("accepted", this.aiText, this.uuid);
      this.close();
    },
    close() {
      this.$refs.overlayOptimize.hide();
      this.aiText = "";
    },
    toggleDialogError(msg) {
      this.$toast.add({
        severity: "error",
        summary: "Fehler",
        detail: msg || this.$t("timeTracking.msgError"),
        life: 8000,
      });
    },
  },
};
</script>
