export default {
  "breadcrumbs": {
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "dataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Protection"])},
    "profileOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile overview"])},
    "tasks": {
      "allOpenTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All open tasks"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
      "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
      "employeeManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee management"])},
      "performanceRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance records"])},
      "completedTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed tasks"])},
      "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task"])},
      "employeeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee number"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
      "calculateFirstDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task - Calculate first working day"])},
      "employeeID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EmployeeID"])},
      "onboardingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboarding date"])},
      "firstDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First working day"])},
      "msgFirstDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the first working day."])},
      "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "editPerformanceRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit performance record"])},
      "taskDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task description:"])},
      "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project name:"])},
      "commissioningDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioning description:"])},
      "createdProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project created:"])},
      "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee:"])},
      "associatedMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associated month:"])},
      "downloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download file"])},
      "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select file"])},
      "completeTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete task"])},
      "activeEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# active employee:"])},
      "processSepaTransferFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process SEPA transfer file"])},
      "payrolls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payrolls:"])},
      "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
      "payroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payroll"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total:"])},
      "approveTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve training"])},
      "requestTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for training"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
      "offerer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerer"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
      "editSodexoCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Sodexo card"])},
      "sodexoID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SodexoID"])},
      "payTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay training"])},
      "trainingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training details"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "checkChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check changes for"])},
      "changedField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed fields"])},
      "newValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New value"])},
      "declineChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline changes"])},
      "acceptChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept changes"])},
      "manualAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual assignment of performance records"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "employeeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee name"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
      "registeredEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered E-Mail"])},
      "selectProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select project"])},
      "orderNewHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order new hardware"])},
      "updateHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update hardware"])},
      "sendHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send hardware"])},
      "collectOldHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect old hardware"])},
      "expiryProvePhoneContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prove phone contract expiry"])},
      "hardwareName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware name"])},
      "serialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial number"])},
      "contractNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract number"])},
      "expiryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiry date"])},
      "assignedEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned employee"])},
      "assignedPhoneContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned phone contract"])},
      "hardwareManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware management"])},
      "informTaxConsultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inform tax consultant"])},
      "requestHardwareShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request hardware shipping"])},
      "enterShipmentTrackingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter hardware package shipping number"])},
      "lockEmployeeAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock employee accounts"])},
      "offboardingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offboarding date"])},
      "packageTrackingNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package tracking numbers:"])},
      "enterTrackingNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter tracking numbers"])},
      "orderAdminHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Admin hardware"])},
      "assignAdminOffice365License": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Admin Office 365 License"])},
      "submitCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit certificate"])},
      "dragAndDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop files here to upload."])},
      "noCertificateAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No certificate available"])},
      "uploadSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload successful"])},
      "uploadFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload failed"])},
      "childIllnessTimeOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach medical certificate for child illness"])},
      "beginDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
      "orderHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered Hardware"])},
      "orderAdminLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order admin JetBrains license"])},
      "forEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for employees"])},
      "deletedTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Deleted"])}
    },
    "performanceRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance records"])},
    "clientManagement": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client management"])},
      "addClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Client"])}
    },
    "projectManagement": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project management"])},
      "addProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add project"])}
    },
    "timeTracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time tracking"])},
    "hardwareManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware management"])},
    "employeeManagement": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee management"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboarding"])},
      "offboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offboarding"])},
      "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])}
    },
    "training": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
      "uploadCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload certificate"])}
    },
    "employeeDataManagement": {
      "employeeData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Data"])}
    },
    "performanceRecord": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance Record"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
      "mailRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Registration"])}
    },
    "expenseAccounting": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
    },
    "birthdayCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday calendar"])},
    "absence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence"])},
    "adminConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Configuration"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])}
  },
  "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tim the Time-Butler!"])},
  "homepage": {
    "timeTracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Tracking"])},
    "holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holiday"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
    "projectManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Management"])},
    "hardwareManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware Management"])},
    "employeeManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Management"])},
    "illness-Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illness-Report"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "expenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense report"])},
    "performance-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance Records"])},
    "client-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client management"])},
    "add-client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add client"])},
    "dataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Protection"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])}
  },
  "employee": {
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee-ID"])},
    "personalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee number"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House number"])},
    "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "privateNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private phone number"])},
    "businessPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business phone number"])},
    "privateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private E-Mail address"])},
    "businessEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate E-Mail address"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["male"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["female"])},
    "diverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diverse"])},
    "dayOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
    "placeOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of Birth"])},
    "sodexoId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SodexoID"])},
    "healthCare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthcare institute"])},
    "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social security number"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax ID"])},
    "taxClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax class"])},
    "maritalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marital status"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["single"])},
    "married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["married"])},
    "divorced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divorced"])},
    "widowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["widowed"])},
    "confession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confession"])},
    "evangelical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evangelical"])},
    "roman_catholic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roman-catholic"])},
    "non_denominational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non-denominational"])},
    "hiringDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiring date"])},
    "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary per year before taxes"])},
    "workingHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly working hours"])},
    "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus per day"])},
    "vacationDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation days"])},
    "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job title"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
    "personalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data file"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
    "accountingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounting data"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "firstnameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname is required"])},
    "lastnameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname is required"])},
    "streetRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street is required"])},
    "numberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number is required"])},
    "zipCodeRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code is required"])},
    "cityRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City is required"])},
    "phoneRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private phone number is required"])},
    "businessEmailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business E-Mail is required"])},
    "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private E-Mail address is required"])},
    "birthdayRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day of birth is required"])},
    "placeOfBirthRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of birth is required"])},
    "genderRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender is required"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail is invalid!"])},
    "healthcareRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthcare is required"])},
    "socialSecurityNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social security number is required"])},
    "ibanRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN is required"])},
    "bicRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC is required"])},
    "bankRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank is required"])},
    "taxIDRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax ID is required"])},
    "taxClassRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax class is required"])},
    "maritalStatusRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marital status is required"])},
    "confessionRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confession is required"])},
    "hiringDateRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiring date is required"])},
    "salaryPerYearRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary per year is required"])},
    "workingHoursRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly working hours is required"])},
    "bonusRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus is required"])},
    "vacationDaysRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation days are required"])},
    "jobTitleRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job title is required"])},
    "departmentRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department is required"])},
    "contractRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract is required"])},
    "personalDataRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data is required"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "msgEmptyFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are required fields which are empty"])},
    "selectGender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select gender"])},
    "selectTaxClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select tax class"])},
    "selectMaritalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select marital status"])},
    "selectConfession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select confession"])},
    "selectDepartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select department"])},
    "searchName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])},
    "updateEmployeeData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Employee Data"])},
    "employeeData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Data"])},
    "onlyLetters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please type in letters only!"])},
    "invalidNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please type in a correct house number!"])},
    "invalidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please type in a correct phone number!"])},
    "invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please type in a correct date!"])},
    "invalidSocialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please type in a correct social security number!"])},
    "wrongIbanFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The given IBAN is not correct!"])},
    "invalidBIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The given BIC is not correct!"])},
    "invalidTaxID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please type in a correct tax ID!"])},
    "invalidAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please type in a correct amount!"])},
    "invalidZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please type in a correct zip code!"])},
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select file"])},
    "submitForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for submitting the form"])},
    "mailAddressNoAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The e-mail address is already taken, please choose a different address"])}
  },
  "profileOverview": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job title"])},
    "lastModification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last modification"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "newProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new profile"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "createSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile created"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile deleted"])},
    "deleteEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the profile?"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete profile"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "selectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select employee"])},
    "optimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimize"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, wait..."])}
  },
  "profile": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
  },
  "offboarding": {
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
    "selectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select employee"])},
    "msgSelectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an employee"])},
    "terminationBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination by:"])},
    "worker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker"])},
    "employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employer"])},
    "terminationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a termination type"])},
    "terminationTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination to:"])},
    "selectValidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a valid date"])},
    "shipmentTrackingNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package tracking number:"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment:"])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment:"])},
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select file"])},
    "finishTermination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finish termination legally binding"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "invalidShipmentTrackingNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The package tracking number is invalid!"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "selectGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select grade"])},
    "invalidGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose a grade"])},
    "gradeA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["very good"])},
    "gradeB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["good"])},
    "gradeC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["satisfactory"])},
    "gradeD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sufficient"])},
    "gradeE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poor"])},
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is mandatory"])},
    "quitGenerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offboarding created"])}
  },
  "timeTracking": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time tracking"])},
    "workedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What have you worked on?"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
    "internalWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal work"])},
    "interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview"])},
    "illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illness"])},
    "travelTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel time"])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation"])},
    "msgContingent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This entry cannot be saved, because there are not enough contingent available."])},
    "msgDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The specified date does not match the commissioning time period."])},
    "msgEmptyFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This entry cannot be saved, because there are empty fields."])},
    "msgSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry is saved successfully"])},
    "msgError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown error"])},
    "selectNewDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select new date"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "contingentExhausted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Entry can not be saved, your contingent is exhausted"])},
    "unknownError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown Error during update"])},
    "errorSavingChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving the changes"])},
    "savedChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved successfully"])},
    "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required!"])},
    "tenHoursRestriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum working time of 10 hours exceeded!"])},
    "onlyAlphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only alpha numeric characters are allowed!"])},
    "invalidTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The time overlaps with the time of another entry."])},
    "invalidTimeDelta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End time must be after start time!"])},
    "errorCommissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one of the entries would be outside the order period when copied."])},
    "msgBreak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify break: 30min for 6h, 45min for 9h."])},
    "msgIntersection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conflicting time entries."])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "projectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task - Project"])},
    "totalAboveTenHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total time must not exceed 10h."])},
    "msgStartTimeLimits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start time must be after 4:00 AM."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "entryNotEditableAnymore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This entry is not editable anymore"])},
    "deletionSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry successful deleted"])},
    "weeklyHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly Hours"])}
  },
  "illnessReport": {
    "illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illness"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illness Report"])},
    "enterIllnessReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter illness report"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "giveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please give a date"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select file"])},
    "illnessReportChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illness report child"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
    "threeDaysOrMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical certificate is required from 3 days"])}
  },
  "performanceRecord": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance Record"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "mailRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Registration"])},
    "previewPerformanceRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview performance record: "])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])},
    "submitPerformanceRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit performance record"])},
    "selectProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select project"])},
    "selectMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select month"])},
    "selectDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select document"])},
    "checkedPerformanceRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have checked my performance record!"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "savedPerformanceRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved performance record"])},
    "invalidDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid document"])},
    "savedEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address was saved successfully"])},
    "notSavedEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address could not be saved"])},
    "EmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "hidePanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Email-Registration-Panel"])},
    "showPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Email-Registration-Panel"])},
    "selectCommissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select commissioning"])},
    "selectPayrollMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select payroll month"])},
    "uploadSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload successful"])},
    "uploadFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload failed"])},
    "emailProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Email for project"])},
    "noProjectAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Projects available"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "titlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter title"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter consultant"])},
    "projectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose project"])},
    "selectRecordLanguagePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose language"])},
    "selectRecordLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "selectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Name"])},
    "selectEmployeePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose employee"])},
    "columnDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "columnStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start time"])},
    "columnEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End time"])},
    "columnDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "columnHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
    "columnRemote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote/On-Site"])},
    "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote"])},
    "onSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Site"])},
    "remoteHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote hours"])},
    "onSiteHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Site hours"])},
    "totalHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total hours"])},
    "remoteDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote days"])},
    "onSiteDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Site days"])},
    "totalDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total days"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate PDF"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance_record"])},
    "projectId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project ID"])},
    "externalProjectId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External project ID"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "warningDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a date."])},
    "warningProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a project."])},
    "signatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatory"])},
    "signatoryPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter signatory"])},
    "warningSignatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a signatory."])},
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])}
  },
  "trainingHome": {
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training request"])},
    "openTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["open Tasks"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "uploadCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload certificate"])}
  },
  "trainingRequest": {
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
    "offerer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerer"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required!"])},
    "startBeforeEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The start have to be before the end!"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "msgSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training was applied for approval."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "msgError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error sending the request!"])},
    "invalidPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price must not be negative!"])},
    "invalidURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please give a correct url (https://)"])}
  },
  "uploadCertificate": {
    "uploadCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload certificate"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "validTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid to"])},
    "issuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuer"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select file"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "errorSelectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a file"])},
    "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required!"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "msgSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate was uploaded successfully"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "msgError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not upload certificate"])}
  },
  "trainingList": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
    "offerer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerer"])},
    "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])}
  },
  "employeeManagementHome": {
    "employeeOnBoarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Onboarding"])},
    "employeeOffBoarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Offboarding"])},
    "openTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Tasks"])},
    "employeeOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Overview"])}
  },
  "absenceOverview": {
    "employeeAbsenceOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absences"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence Overview"])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has vacation"])},
    "specialLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has special leave"])},
    "unpaidVacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has unpaid vacation"])},
    "illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has illness"])},
    "illnessChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Child has illness'"])}
  },
  "employeeOnboardingMenu": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee OnBoarding"])}
  },
  "onBoardingUploadForm": {
    "uploadContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload your contract as PDF here:"])},
    "uploadPersonalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload your personal data file as PDF here:"])}
  },
  "employeeOverview": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee overview"])}
  },
  "changeEmployee": {
    "genderMale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "genderFemale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "genderDiverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse"])},
    "maritalStatus-single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single"])},
    "maritalStatus-married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Married"])},
    "maritalStatus-separated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separated"])},
    "maritalStatus-widowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widowed"])}
  },
  "employeeAbsenceMenu": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Absence Overview"])}
  },
  "tasks": {
    "tabPanel-openTasks-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open tasks"])},
    "tabPanel-closedTasks-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished tasks"])},
    "tab-panel-performance-records-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance records"])},
    "task-column-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task"])},
    "firstName-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastName-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "email-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "description-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "created-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of creation"])},
    "show": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Shows ", _interpolate(_named("first")), " of ", _interpolate(_named("last")), " of ", _interpolate(_named("totalRecords")), " entries"])},
    "keywordSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for"])},
    "description": {
      "edit-sodexo-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly loading of Sodexo cards"])}
    },
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select file"])},
    "taskDefinitionKeys": {
      "Check_Application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Vacation request"])},
      "Task_ReviewExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Expense report"])},
      "check_change_employee_data_by_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify change by admin"])},
      "Admin_Assign_A_Office_365_License": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign a office 365 license"])},
      "Admin_Order_Hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Hardware"])},
      "Admin_Order_JetBrains_License": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order jetBrains license"])},
      "Edit_Sodexo_List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Sodexo List"])},
      "DataProtectionUsertask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DSGVO"])},
      "Task_ProcessExpenseReportSepa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process ExpenseReport Sepa File"])},
      "CellPhone_contract_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check phone contract renewal"])},
      "Order_New_Hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order New Hardware"])},
      "Maintain_Hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintain Hardware"])},
      "Ship_Hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship Hardware"])},
      "Pick_Up_Old_Hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Up Old Hardware"])},
      "RequestReturnHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Hardware Shipping"])},
      "EditHardwareTrackingnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Hardware Package Tracking Number"])},
      "LockAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block employee accounts"])},
      "Activity_0vl4ayf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Sodexo card order"])},
      "Proof_Performance_Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit proof of performance"])},
      "Process_Sepa_Transfer_File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process Sepa Transfer File"])},
      "PermitTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permit training"])},
      "PayTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay training"])},
      "UploadCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload certificate"])},
      "Attach_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach certificate"])}
    }
  },
  "employeeManagementPage": {
    "break": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determine break"])},
    "terminate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determine termination date"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Employee data"])},
    "breakDialog-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determine break"])},
    "breakDialog-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the reason you want to take a break?"])},
    "terminateDialog-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determine termination date"])}
  },
  "createButtonForm": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a custom Widget"])},
    "chooseLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a logo"])},
    "widgetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "widgetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create the Widget"])}
  },
  "deleteButtonForm": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which widget would you like to remove?"])}
  },
  "displayFiles": {
    "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File type"])},
    "fileCreatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of creation"])},
    "addFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add file"])},
    "chooseFileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the file type"])}
  },
  "MISC-Button": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "backToHomePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the Homepage"])}
  },
  "MISC": {
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "asOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As of"])}
  },
  "equipment": {
    "employeeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee name"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial number"])},
    "functional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functional"])},
    "broken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broken"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "addNewHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new hardware"])},
    "createTransferOfUseFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create transfer of use for"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "createHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create hardware"])},
    "hardwareCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware created"])},
    "updateHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update hardware"])},
    "hardwareDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware deleted"])},
    "changedHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware updated"])},
    "giveName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please give a name"])},
    "giveSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please give a serial number"])},
    "contractNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract number"])},
    "runTimeFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run-time from"])},
    "runTimeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run-Time to"])},
    "assignEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assign employee"])},
    "assignPhoneContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assign phone contract"])},
    "selectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select employee"])},
    "hardwareType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware type"])},
    "selectHardwareType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select hardware type"])},
    "addDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add documents"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "notAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not assigned"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "deleteEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete the entry?"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "invalidSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial number is invalid!"])},
    "usedSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial number already in use"])},
    "invalidName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is invalid"])},
    "invalidContractNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractnumber is invalid"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])},
    "serialPrintLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - Serialnumber: "])},
    "serialNumberDuplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serialnumber already assigned"])},
    "clearSearchField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear search field"])},
    "deleteHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete hardware"])},
    "createTransferOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create transfer of use"])}
  },
  "Project": {
    "createProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new project"])},
    "editProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit project"])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment project description"])},
    "msgArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to archive the entry?"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "createdProjectSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project was successfully created"])},
    "changedProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project was successfully changed"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
    "projectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project description"])},
    "commissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioning"])},
    "commissionings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissionings"])},
    "noCommissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No commissioning available"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of project"])},
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal project number"])},
    "externalProjectId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External project number"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "hourlyRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly rate"])},
    "dailyRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily rate"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
    "selectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select employee"])},
    "errorSelectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an employee"])},
    "errorDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please give a description"])},
    "errorValidDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please give a valid start and end date"])},
    "errorRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please give a rate"])},
    "errorProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please give a project name"])},
    "errorProjectNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please give a project number"])},
    "errorProjectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a file"])},
    "errorFillInFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are required fields which are empty"])},
    "errorSelectClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a client"])},
    "errorProjectId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ProjectId already exists"])},
    "errorProjectNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project was not found"])},
    "errorNoUniqueProjectId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are multiple projects with the requested project ID."])},
    "msgEntryExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry already exists"])},
    "errorInternalProjectCannotBeArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The internal project cannot be archived."])},
    "errorCommissioningCannotBeCreatedWhenInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A commissioning for an inactive project cannot be created."])},
    "msgEntryExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overlapping entries"])},
    "msgEntryAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry was added"])},
    "msgEntryArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry was archived"])},
    "msgSelectCommissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a commissioning to edit it"])},
    "msgArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot track your time on an inactive commissioning."])},
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select file"])},
    "onlyNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only valid Strings are allowed"])},
    "timeSpecification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time indication "])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
    "projectDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project days"])},
    "hoursPerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours per day"])},
    "hoursOnSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours on site"])},
    "agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agreement"])},
    "warningClientNotSelectedWhenEditingSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No client selected"])},
    "warningClientNotSelectedWhenEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a client by clicking on a row in the table above."])}
  },
  "client": {
    "basicInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])},
    "clientAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client address"])},
    "invoiceAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice address"])},
    "msgClientCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client was created successfully"])},
    "msgClientChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client was changed successfully"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "msgError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not all required fields are filled in"])},
    "msgClientError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client could not be saved"])},
    "addClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add client"])},
    "editClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit client"])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment project description"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "billing-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Address"])},
    "contact-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact E-Mail"])},
    "invoice-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice E-Mail"])},
    "phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "contact-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House number"])},
    "zip-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "errorEmptyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact E-Mail is empty"])},
    "errorEmptyInvoiceEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice E-mail is empty"])},
    "errorEmptyPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number is empty"])},
    "errorEmptyContactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person is empty"])},
    "errorEmptyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is empty"])},
    "errorEmptyCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City is empty"])},
    "errorEmptyZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code is empty"])},
    "errorEmptyStreet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street is empty"])},
    "errorEmptyHouseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House number is empty"])},
    "msgArchiveEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to archive the entry"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
    "takeClientAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use client address"])}
  },
  "vacationDaysPage": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Off"])},
    "requestTimeOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Time Off"])},
    "selectVacationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Vacation Type"])},
    "selectTheDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select The Date"])},
    "timeOfPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Off Policy"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total:"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available:"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "addNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a note"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekends and holidays won't count as days off."])},
    "currentState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State of vacation"])},
    "requestMadeBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This vacation Request was made by"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "maxCharsComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can input a total of "])},
    "chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" characters"])},
    "holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holiday"])}
  },
  "vacationDaysPage-tabs": {
    "showPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Pending"])},
    "showApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Approved"])},
    "showRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Rejected"])}
  },
  "vacationDaysPage-tabs-requests": {
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REQUESTS"])},
    "teamMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEAM MEMBER"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERIOD"])},
    "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REQUESTED"])},
    "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POLICY"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STATUS"])},
    "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
    "requestMadeByManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request made by manager"])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VACATION"])},
    "specialLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPECIAL LEAVE"])},
    "unpaidVacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UNPAID LEAVE"])},
    "annualLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNUAL LEAVE"])},
    "dateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM/DD, yy"])},
    "dateFormat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM/DD"])},
    "showTimeOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the vacation type."])},
    "monthNames": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
    ],
    "monthNamesShort": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feb"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apr"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jul"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aug"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sep"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oct"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nov"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dec"])}
    ]
  },
  "vacationDaysPage-tabs-balances": {
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BALANCE"])},
    "accrued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCRUED"])},
    "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USED"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVAILABLE"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "balanceHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance History"])},
    "balanceHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VACATION"])}
  },
  "vacationDaysPage-tabs-timeline": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIMELINE"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["today"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])}
  },
  "vacationDaysPage-toast-msg": {
    "summarySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Message"])},
    "successDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for vacation"])},
    "summaryError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info Message"])},
    "summaryErrorDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already requested vacation on these days"])},
    "errorDetailMsgForAlreadyTookVacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overlapping vacation"])},
    "errorDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation request denied"])},
    "errorDetailMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "entryErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This entry cannot be saved because not all fields are filled."])},
    "entrySuccessMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry saved successfully"])},
    "entryUnknownMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown error"])},
    "errorDuplicateMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error duplicating the entry"])},
    "vacationStartsTodayOrInPast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already taken this vacation and cannot delete it."])},
    "summeryMessageDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't want to take a vacation."])},
    "rejectedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
    "rejectedMsgDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have rejected."])},
    "applicationRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application rejected"])},
    "notEnoughVacations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have enough vacation."])},
    "pendingMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "pendingMsgForDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error, please reload and try again."])}
  },
  "vacationsDaysTask": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task - Vacation request"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created date:"])},
    "vacationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of Vacations Days"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date:"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date:"])},
    "vacationsType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacations Type:"])},
    "remainingVacationsDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Vacation Days:"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days:"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment:"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])}
  },
  "vacationsDaysTask-confirmDialog-msg": {
    "msgDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee does not have enough vacation days."])},
    "msgDetailQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want a vacation request approved or denied?"])},
    "confirmMsgForDeleteQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you wish to withdraw?"])},
    "confirmMsgForDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])}
  },
  "vacationsDaysTask-toast-msg": {
    "successSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
    "successDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacations Days accepted."])},
    "rejectSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
    "rejectDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation Days rejected."])}
  },
  "contractModificationPage": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Modification"])},
    "employeeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Name"])},
    "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Valid from"])},
    "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
    "workingHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly working hours"])},
    "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus"])},
    "contractUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Upload"])},
    "createNewContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new Contract"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose file"])},
    "validation-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field is required!"])},
    "successToast-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "successToast-detailMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Contract created."])},
    "errorToast-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "errorToast-detailMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error creating Contract"])},
    "placeholder-employee-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an Employee Name"])}
  },
  "skills": {
    "PROFIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROFILE"])},
    "SKILLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKILLS"])},
    "PROJEKTAUSZUG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROJECT EXCERPT"])},
    "CODING_LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coding languages"])},
    "FRAMEWORK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frameworks"])},
    "DATABASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Databases"])},
    "TOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality assurance"])},
    "SECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector"])},
    "ROLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
    "STACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stack"])},
    "METHOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methods"])},
    "newEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New entry"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "ZERTIFIKATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CERTIFICATES"])},
    "LANGUAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LANGUAGES"])}
  },
  "required": {
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["required Field"])}
  },
  "overviewOfEmployee": {
    "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Data"])},
    "accountingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounting data"])},
    "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Data"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "errorDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["employee could not be saved."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "successDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data are compared."])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "checkValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the validation"])}
  },
  "confirmation": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date / Month"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])}
  },
  "consentForm": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "dataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Protection"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "agreePoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the the following points:"])},
    "checkboxDataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" I consent to data processing in accordance with the data protection declaration"])},
    "checkboxBirthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" I agree to the internal sharing of my date of birth"])},
    "errorMissingConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must agree to the data protection form."])}
  },
  "attestAttachTask": {
    "filesEmptyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No file was attached."])}
  },
  "orderHardware": {
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which hardware do you need?"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For your work from your home office, we provide you with standard equipment consisting of:"])},
    "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you don't need some components because you are already fully equipped or can't place everything due to lack of space, you have the option of putting together your equipment according to your requirements. The only thing you can't cancel is the MacBook, which you will always receive."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "orderCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you, your selection has been submitted."])},
    "confirmDialogHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you selected everything correctly?"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "birthdayCalendar": {
    "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["today"])}
  },
  "expenseAccounting": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "addExpense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add expense"])},
    "viewOnlyExpense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View only expense"])},
    "updateExpense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update expense"])},
    "employeeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee name"])},
    "dayOfExpense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "expenseCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "chooseCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Category"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "priceTotalBrutto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total (gross)"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose file"])},
    "expenseFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense files"])},
    "status": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
      "sepaSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferred"])},
      "toDoSepa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will be transferred"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will be checked"])},
      "notSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not submitted"])}
    },
    "billingPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing period"])},
    "sumPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum price"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "addExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add expense report"])},
    "submitReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit expense report"])},
    "nameExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of expense report"])},
    "deleteExpenseQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to delete the expense?"])},
    "deleteExpenseReportQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to delete the expense report?"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "checkInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required"])},
    "emptyExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense report is empty"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
  },
  "performanceRecordManagement": {
    "createPerformanceRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create PerformanceRecord"])},
    "submitPerformanceRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit performance record"])},
    "registerEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register E-Mail"])}
  },
  "reviewExpenseReportTask": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review - Expense report"])},
    "expenseReportDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense report details"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "entrydate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry date:"])},
    "dayOfExpense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "expenseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "expenseCategoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount (gross)"])},
    "hiringDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiring date:"])},
    "nameExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "statusExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "sumPriceExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount (gross)"])},
    "monthYearExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing period"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "viewExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check expense report"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "processExpenseReportSepaTask": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPA-Transfer"])},
    "downloadSepa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download SEPA-File"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm transfer"])}
  },
  "adminConfigurations": {
    "workHoursLockDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days till work hour booking is no longer editable"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "changingValueSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing the value was successful"])},
    "dataProtectionConfirmationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new privacy policy"])},
    "bothTextsAltered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you update the privacy policy texts in both languages?"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "updatePrivacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update privacy policy"])},
    "englishVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English version"])},
    "germanVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German version"])},
    "changingPrivacyPolicySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing privacy policy was successful"])},
    "requestVacationForOtherEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request vacation for other employee"])},
    "selectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select employee"])},
    "requestVacationFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request vacation for"])},
    "notEverythingFilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not all required fields are filled"])},
    "timeTrackingConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Tracking Configuration"])},
    "dataProtectionConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Protection Configuration"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "createAnnualLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit employee annual leave"])},
    "updateEmployeeAnnualLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit annual leave for"])},
    "annualLeaveDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual leave entitlement"])},
    "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required!"])},
    "summarySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Message"])},
    "successDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual leave has been successfully edited."])},
    "documentOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Overview"])}
  },
  "days": {
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])}
  },
  "validation": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation error"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Iban"])},
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Bic"])}
  },
  "errorPage": {
    "errorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This error can have various causes, including the backend not being reachable or no user being found."])}
  },
  "report": {
    "vacationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation report"])},
    "timeTrackingReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time tracking report"])},
    "cashFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashflow forecast"])},
    "VACATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation"])},
    "OFFBOARDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offboarding"])},
    "UNPAIDVACATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid vacation"])},
    "SPECIALLEAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special leave"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation requests"])},
    "annualLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual leave overview"])},
    "selectRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select range"])},
    "filterByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by name"])},
    "filterByType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by Type"])},
    "selectStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select status"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "filterByUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by user"])},
    "filterByAnnualLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by annual leave"])},
    "filterByUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by used vacation days"])},
    "filterByAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter be available vacation days"])},
    "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Date"])}
  },
  "timeTrackingReport": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worked on"])},
    "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project name"])},
    "projectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project description"])},
    "billable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billable"])},
    "startTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
    "endTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time"])},
    "calendarValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "ticketId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Id"])},
    "Remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote"])},
    "filterByTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by ticket"])}
  },
  "docOveriew": {
    "docType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Type"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "docName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Name"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])}
  },
  "cashFlow": {
    "cashFlowForeCast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashflow forecast"])}
  },
  "absence": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaub stornieren"])},
    "summarySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation sucessfully cancelled"])}
  }
}