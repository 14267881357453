import {RequestClient} from "@/service/RequestClient";

const apiClient = new RequestClient("profile");

export default {
    updateProfile(profile) {
        return apiClient.put(
            `/updateProfile`,
            profile
        );
    },
    getAllSkills() {
        return apiClient.get(`/skills`);
    },

    getAllProfiles() {
        return apiClient
            .get(`/list`)
            .catch((error) => console.log(error));
    },
    createEmptyProfile(id) {
        return apiClient.get(`/create/${id}`);
    },
    deleteProfile(id) {
        return apiClient.delete(`/delete/${id}`);
    },
};
