import {loginRequest, msalInstance} from "./authConfig";
import {InteractionRequiredAuthError} from "@azure/msal-browser";

export function registerGuard(router) {
    router.beforeEach(async (to) => {
        if (!to.meta.requiresAuth || window.Cypress) {
            return true;
        }

        // if user is authenticated continue with request
        if (await isAuthenticated()) {
            return true
        }

        // otherwise try to silently refresh the session
        try {
            await msalInstance.ssoSilent(loginRequest);
            return true;
        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {
                // redirect to login page, any following code is not executed
                msalInstance.loginRedirect({ ...loginRequest, redirectUri: to.fullPath });
            } else {
                return "/error";
            }
        }
    });
}

async function isAuthenticated() {
    return msalInstance.handleRedirectPromise()
      .then(() => {
          if (msalInstance.getAllAccounts().length > 0) {
              return true;
          }
      })
      .catch(() => {
          return false;
      });
}