export default {
  "breadcrumbs": {
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaub"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "dataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "profileOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilübersicht"])},
    "tasks": {
      "allOpenTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle offenen Aufgaben"])},
      "editSodexoCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sodexo Liste erstellen"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
      "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortbildung"])},
      "employeeManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiterverwaltung"])},
      "performanceRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungsnachweise"])},
      "completedTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossene Aufgaben"])},
      "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe"])},
      "employeeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter/innummer"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
      "calculateFirstDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe - Ersten Arbeitstag berechnen"])},
      "employeeID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MitarbeiterID"])},
      "onboardingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingestellt am"])},
      "firstDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erster Arbeitstag"])},
      "msgFirstDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie den ersten Arbeitstag."])},
      "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigen"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
      "editPerformanceRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungsnachweis bearbeiten"])},
      "taskDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabenbeschreibung:"])},
      "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektname:"])},
      "commissioningDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommissionierungsbeschreibung:"])},
      "createdProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt erstellt:"])},
      "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter:"])},
      "associatedMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungsmonat:"])},
      "downloadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei herunterladen"])},
      "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei wählen"])},
      "completeTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe erledigen"])},
      "activeEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["# aktiver Mitarbeiter:"])},
      "processSepaTransferFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPA-Überweisung"])},
      "payrolls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehaltsabrechnungen:"])},
      "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehalt"])},
      "payroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehaltsabrechnung"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtsumme:"])},
      "approveTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortbildung genehmigen"])},
      "requestTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antrag auf Fortbildung"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
      "offerer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
      "sodexoID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SodexoID"])},
      "payTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortbildung bezahlen"])},
      "trainingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainingsdetails"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlt"])},
      "checkChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen überprüfen für"])},
      "changedField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geänderte Felder"])},
      "newValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Wert"])},
      "declineChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen ablehnen"])},
      "acceptChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen akzeptieren"])},
      "manualAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelle Zuweisung von Leistungsnachweisen"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
      "employeeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeitername"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
      "registeredEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierte E-Mail"])},
      "selectProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt auswählen"])},
      "orderNewHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Hardware bestellen"])},
      "updateHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware einpflegen"])},
      "sendHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware versenden"])},
      "collectOldHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alte Hardware abholen"])},
      "expiryProvePhoneContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablauf Handyvertrag prüfen"])},
      "hardwareName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardwarename"])},
      "serialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seriennummer"])},
      "contractNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsnummer"])},
      "expiryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablaufdatum"])},
      "assignedEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugeordneter Mitarbeiter"])},
      "assignedPhoneContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugeordneter Handyvertrag"])},
      "hardwareManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardwareverwaltung"])},
      "informTaxConsultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerberater informieren"])},
      "requestHardwareShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardwareversand anfordern"])},
      "enterShipmentTrackingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware Paket-Sendungsnummer eintragen"])},
      "lockEmployeeAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter Accounts sperren"])},
      "offboardingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausscheidungsdatum"])},
      "packageTrackingNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket-Sendungsnummern:"])},
      "enterTrackingNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Sendungsnummern eingeben"])},
      "orderAdminHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Hardware bestellen"])},
      "assignAdminOffice365License": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Office 365 Lizenz zuweisen"])},
      "submitCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikat einreichen"])},
      "dragAndDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Hochladen Datei hierhin ziehen und ablegen"])},
      "noCertificateAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Zertifikat vorhanden"])},
      "uploadSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload erfolgreich"])},
      "uploadFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload fehlgeschlagen"])},
      "childIllnessTimeOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attest bei Kind Krank anhängen"])},
      "beginDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])},
      "orderHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angeforderte Hardware"])},
      "orderAdminLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin JetBrains Lizenz bestellen"])},
      "forEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["für Mitarbeiter"])},
      "deletedTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe gelöscht"])}
    },
    "performanceRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungsnachweise"])},
    "clientManagement": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenverwaltung"])},
      "addClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde hinzufügen"])}
    },
    "projectManagement": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektverwaltung"])},
      "addProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Projekt erstellen"])}
    },
    "timeTracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiterfassung"])},
    "hardwareManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardwareverwaltung"])},
    "employeeManagement": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiterverwaltung"])},
      "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht aller Mitarbeiter"])},
      "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboarding"])},
      "offboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offboarding"])},
      "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag"])}
    },
    "training": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortbildung"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht aller Fortbildungen"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortbildung beantragen"])},
      "uploadCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikat hochladen"])}
    },
    "employeeDataManagement": {
      "employeeData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten der Mitarbeiter"])}
    },
    "performanceRecord": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungsnachweis"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreichen"])},
      "mailRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Registration"])}
    },
    "expenseAccounting": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesenabrechnung"])}
    },
    "birthdayCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtstagskalendar"])},
    "absence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheiten"])},
    "adminConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Konfiguration"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])}
  },
  "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tim der Butler!"])},
  "homepage": {
    "timeTracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitszeiterfassung"])},
    "holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaubsantrag"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortbildung"])},
    "projectManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektverwaltung"])},
    "hardwareManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardwareverwaltung"])},
    "employeeManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiterverwaltung"])},
    "illness-Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankmeldung"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "expenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesenabrechnung"])},
    "performance-records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungsnachweise"])},
    "client-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenverwaltung"])},
    "add-client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde hinzufügen"])},
    "dataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])}
  },
  "profileOverview": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobtitel"])},
    "lastModification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Änderung"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
    "newProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Profil erstellen"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
    "createSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil erstellt"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil gelöscht"])},
    "deleteEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wollen Sie das Profil löschen?"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil löschen"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "selectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter wählen"])},
    "optimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimieren"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte warten..."])}
  },
  "profile": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])}
  },
  "employee": {
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter-ID"])},
    "personalID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiternummer"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer"])},
    "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnort"])},
    "privateNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon (privat)"])},
    "businessPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon (business)"])},
    "privateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail (privat)"])},
    "businessEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmerische E-Mail Adresse"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlecht"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["männlich"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weiblich"])},
    "diverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divers"])},
    "dayOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "placeOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsort"])},
    "sodexoId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sodexoID"])},
    "healthCare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenkasse"])},
    "socialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sozialversicherungsnummer"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankinstitut"])},
    "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steueridentifikationsnummer"])},
    "taxClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerklasse"])},
    "maritalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familienstand"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ledig"])},
    "married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verheiratet"])},
    "divorced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geschieden"])},
    "widowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verwitwet"])},
    "confession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfession"])},
    "evangelical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evangelisch"])},
    "roman_catholic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["römisch-katholisch"])},
    "non_denominational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["konfessionslos"])},
    "hiringDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsbeginn"])},
    "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresgehalt in Brutto"])},
    "workingHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochenarbeitszeit"])},
    "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus pro Tag"])},
    "vacationDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaubstage"])},
    "jobTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufsbezeichnung"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsvertrag"])},
    "personalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalbogen"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
    "accountingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungsdaten"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "firstnameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname ist erforderlich"])},
    "lastnameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname ist erforderlich"])},
    "streetRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße ist erforderlich"])},
    "numberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer ist erforderlich"])},
    "zipCodeRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ ist erforderlich"])},
    "cityRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt ist erforderlich"])},
    "phoneRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Telefonnummer ist erforderlich"])},
    "businessEmailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmens-E-Mail ist erforderlich"])},
    "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private E-Mail ist erforderlich"])},
    "birthdayRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum ist erforderlich"])},
    "placeOfBirthRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsort ist erforderlich"])},
    "genderRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlecht ist erforderlich"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail ist ungültig!"])},
    "healthcareRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenkasse ist erforderlich"])},
    "socialSecurityNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sozialversicherungsnummer ist erforderlich"])},
    "ibanRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN ist erforderlich"])},
    "bicRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC ist erforderlich"])},
    "bankRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank ist erforderlich"])},
    "taxIDRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer ID ist erforderlich"])},
    "taxClassRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerklasse ist erforderlich"])},
    "maritalStatusRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familienstand ist erforderlich"])},
    "confessionRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfession ist erforderlich"])},
    "hiringDateRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsbeginn ist erforderlich"])},
    "salaryPerYearRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresgehalt ist erforderlich"])},
    "workingHoursRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochenarbeitszeit ist erforderlich"])},
    "bonusRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus ist erforderlich"])},
    "vacationDaysRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaubstage sind erforderlich"])},
    "jobTitleRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobtitel ist erforderlich"])},
    "departmentRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department ist erforderlich"])},
    "contractRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsvertrag ist erforderlich"])},
    "personalDataRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Daten sind erforderlich"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "msgEmptyFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden nicht alle Pflichtfelder ausgefüllt"])},
    "selectGender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlecht wählen"])},
    "selectTaxClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerklasse wählen"])},
    "selectMaritalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familienstand wählen"])},
    "selectConfession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfession wählen"])},
    "selectDepartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department wählen"])},
    "searchName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Namen suchen"])},
    "updateEmployeeData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiterdaten ändern"])},
    "employeeData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiterdaten"])},
    "onlyLetters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte nur Buchstaben eingeben!"])},
    "invalidNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine korrekte Hausnummer an!"])},
    "invalidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine korrekte Telefonnummer an!"])},
    "invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie ein korrektes Datum an!"])},
    "invalidSocialSecurityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine korrekte Sozialversicherungsnummer ein!"])},
    "wrongIbanFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eingegebene IBAN ist nicht korrekt!"])},
    "invalidBIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC ist nicht korrekt!"])},
    "invalidTaxID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine korrekte Steuernummer ein!"])},
    "invalidAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie einen korrekten Betrag ein!"])},
    "invalidZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine korrekte PLZ an!"])},
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei wählen"])},
    "submitForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke für das Einreichen des Formulars"])},
    "mailAddressNoAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Email Adresse ist schon vergeben, bitte wähle einen eine andere Adresse"])}
  },
  "offboarding": {
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
    "selectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter wählen"])},
    "msgSelectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte einen Mitarbeiter auswählen"])},
    "terminationBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kündigung durch:"])},
    "worker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitnehmer"])},
    "employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitgeber"])},
    "terminationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Kündigungtyp wählen"])},
    "terminationTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kündigung zum:"])},
    "selectValidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ein gültiges Datum eingeben"])},
    "shipmentTrackingNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket-Sendungsnummern:"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar:"])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagen:"])},
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei wählen"])},
    "finishTermination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kündigung rechtsverbindlich abschließen"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "invalidShipmentTrackingNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Paket-Sendungsnummer ist nicht gültig!"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Zeugniss Note"])},
    "selectGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeugniss Note wählen"])},
    "invalidGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Note wählen"])},
    "gradeA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sehr gut"])},
    "gradeB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gut"])},
    "gradeC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["befriedigend"])},
    "gradeD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ausreichend"])},
    "gradeE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mangelhaft"])},
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld ist verpflichtend"])},
    "quitGenerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offboarding angelegt"])}
  },
  "timeTracking": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitszeiterfassungg"])},
    "workedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woran arbeitest du?"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortbildung"])},
    "internalWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Arbeit"])},
    "interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview"])},
    "illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankheit"])},
    "travelTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisezeit"])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaub"])},
    "msgContingent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Eintrag kann nicht gespeichert werden, da nicht genügend Kontingent vorhanden ist."])},
    "msgDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das angegebene Datum liegt außerhalb des Auftragszeitraums."])},
    "msgEmptyFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Eintrag kann nicht gespeichert werden, da nicht alle Felder ausgefüllt sind."])},
    "msgSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag erfolgreich gespeichert"])},
    "msgError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Fehler"])},
    "msgArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf eine archivierte Beauftragung kann nicht gebucht werden."])},
    "selectNewDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Datum wählen"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
    "contingentExhausted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Eintrag konnte nicht gespeichert werden, da dein Kontingent aufgebraucht ist"])},
    "unknownError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Fehler während des Updates"])},
    "errorSavingChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Speichern der Änderung"])},
    "savedChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung erfolgreich gespeichert"])},
    "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe ist erforderlich!"])},
    "tenHoursRestriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Arbeitszeit von 10h überschritten!"])},
    "onlyAlphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur alphanumerische Zeichen eingeben!"])},
    "invalidTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zeit überschneidet sich mit der Zeit eines anderen Eintrags."])},
    "invalidTimeDelta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endzeit darf nicht vor Startzeit sein!"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "projectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe - Projekt"])},
    "msgBreak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause überprüfen: 30min bei 6h, 45min bei 9h."])},
    "msgIntersection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überschneidende Zeiten."])},
    "errorCommissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens einer der Einträge würde beim Kopieren außerhalb des Auftragszeitraums liegen."])},
    "totalAboveTenHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzeit darf nicht über 10h sein."])},
    "msgStartTimeLimits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startzeit muss nach 4:00 Uhr liegen."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "entryNotEditableAnymore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Eintrag ist nicht mehr änderbar"])},
    "deletionSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag erfolgreich gelöscht"])},
    "weeklyHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wöchentliche Stunden"])}
  },
  "illnessReport": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsunfähigkeitsreport"])},
    "illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsunfähigkeit"])},
    "enterIllnessReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsunfähigkeit eintragen"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis"])},
    "giveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ein Datum angeben"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitteilung"])},
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei wählen"])},
    "illnessReportChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankmeldung Kind"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absenden"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortbildung"])},
    "threeDaysOrMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ärztliches Attest wird ab 3 Tagen benötigt."])}
  },
  "performanceRecord": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungsnachweis"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreichen"])},
    "mailRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Registrierung"])},
    "previewPerformanceRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau Leistungsnachweis: "])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["für"])},
    "submitPerformanceRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungsnachweis einreichen"])},
    "selectProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt auswählen"])},
    "selectMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat auswählen"])},
    "selectDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument auswählen"])},
    "checkedPerformanceRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe meinen Leistungsnachweis geprüft!"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "savedPerformanceRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungsnachweis gespeichert"])},
    "invalidDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Dokument"])},
    "savedEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email-Addresse wurde erfolgreich gespeichert."])},
    "notSavedEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email-Addresse konnte nicht gespeichert werden."])},
    "EmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email-Addresse"])},
    "hidePanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email-Registrierungspanel ausblenden"])},
    "showPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email-Registrierungspanel anzeigen"])},
    "selectCommissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragung auswählen"])},
    "selectPayrollMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungsmonat auswählen"])},
    "uploadSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload erfolgreich"])},
    "uploadFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload fehlgeschlagen"])},
    "emailProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email für Projekt registrieren"])},
    "noProjectAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Projekte vorhanden"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
    "consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berater"])},
    "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "titlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel eingeben"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berater eingeben"])},
    "projectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt auswählen"])},
    "columnDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "columnStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startzeit"])},
    "columnEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endzeit"])},
    "columnDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "columnHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunden"])},
    "columnRemote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote/On-Site"])},
    "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote"])},
    "onSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Site"])},
    "remoteHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Stunden"])},
    "onSiteHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Site Stunden"])},
    "totalHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt Stunden"])},
    "remoteDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Tage"])},
    "onSiteDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Site Tage"])},
    "totalDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt Tage"])},
    "generatePDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF generieren"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschrift"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungsnachweis"])},
    "projectId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt ID"])},
    "externalProjectId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Projekt ID"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
    "warningDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie ein Datum aus."])},
    "warningProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie ein Projekt aus."])},
    "selectRecordLanguagePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache auswählen"])},
    "selectRecordLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
    "selectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeitername"])},
    "selectEmployeePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter auswählen"])},
    "signatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterzeichner"])},
    "signatoryPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterzeichner angeben"])},
    "warningSignatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie einen Unterzeichner an."])},
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])}
  },
  "trainingHome": {
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortbildung beantragen"])},
    "openTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offene Aufgaben"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht aller Fortbildungen"])},
    "uploadCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Zertifikate"])}
  },
  "trainingRequest": {
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beantragen"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortbildung"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])},
    "offerer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld ist erforderlich!"])},
    "startBeforeEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Beginn muss vor dem Ende liegen!"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
    "msgSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortbildung wurde zur Genehmigung beantragt."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "msgError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Senden der Anfrage."])},
    "invalidPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Preis darf nicht negativ sein!"])},
    "invalidURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine korrekte URL an (https://)"])}
  },
  "uploadCertificate": {
    "uploadCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikat hochladen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "validTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])},
    "issuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aussteller"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei wählen"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
    "errorSelectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Datei wählen"])},
    "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld ist erforderlich!"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])},
    "msgSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikat erfolgreich hochgeladen"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "msgError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikat konnte nicht hochgeladen werden"])}
  },
  "trainingList": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "offerer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter"])},
    "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikat"])}
  },
  "employeeManagementHome": {
    "employeeOnBoarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter Onboarding"])},
    "employeeOffBoarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter Offboarding"])},
    "openTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offene Tasks"])},
    "employeeOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht über Mitarbeiter"])}
  },
  "absenceOverview": {
    "employeeAbsenceOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheiten"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheitsübersicht"])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hat Urlaub"])},
    "specialLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hat Sonderurlaub"])},
    "unpaidVacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hat unbezahlter Urlaub"])},
    "illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist krank"])},
    "illnessChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Kind ist krank'"])}
  },
  "employeeOnboardingMenu": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter OnBoarding"])}
  },
  "onBoardingUploadForm": {
    "uploadContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Vertrag bitte als PDF hochladen:"])},
    "uploadPersonalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Personalbogen bitte als PDF hochladen:"])}
  },
  "employeeOverview": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht über alle Mitarbeiter"])}
  },
  "changeEmployee": {
    "genderMale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Männlich"])},
    "genderFemale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiblich"])},
    "genderDiverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers"])},
    "maritalStatus-single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledig"])},
    "maritalStatus-married": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verheiratet"])},
    "maritalStatus-separated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschieden"])},
    "maritalStatus-widowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwitwet"])}
  },
  "employeeAbsenceMenu": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter Absence Overview"])}
  },
  "tasks": {
    "tabPanel-openTasks-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offene Aufgaben"])},
    "tabPanel-closedTasks-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossene Aufgaben"])},
    "tabPanel-performance-records-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungsnachweise"])},
    "task-column-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe"])},
    "firstName-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "lastName-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "email-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "description-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "created-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
    "show": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zeigt ", _interpolate(_named("first")), " von ", _interpolate(_named("last")), " aus ", _interpolate(_named("totalRecords")), " Einträgen"])},
    "keywordSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach Stichwort"])},
    "description": {
      "edit-sodexo-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatliche Beladung der Sodexo-Karten"])}
    },
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei wählen"])},
    "taskDefinitionKeys": {
      "Check_Application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaubsantrag prüfen"])},
      "Task_ReviewExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesenabrechnung prüfen"])},
      "check_change_employee_data_by_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung durch Admin prüfen"])},
      "Admin_Assign_A_Office_365_License": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office 365 Lizenz zuordnen"])},
      "Admin_Order_Hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware bestellen"])},
      "Admin_Order_JetBrains_License": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JetBrains Lizenz bestellen"])},
      "Edit_Sodexo_List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sodexo List prüfen"])},
      "DataProtectionUsertask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DSGVO"])},
      "Task_ProcessExpenseReportSepa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepa File Spesenabrechnung"])},
      "CellPhone_contract_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilfunkvertrag erneuern"])},
      "Order_New_Hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Hardware bestellen"])},
      "Maintain_Hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfe Hardware"])},
      "Ship_Hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versende Hardware"])},
      "Pick_Up_Old_Hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Up alte Hardware"])},
      "RequestReturnHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware zurück geben anfragen"])},
      "EditHardwareTrackingnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware Trackingnummer eingeben"])},
      "LockAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter Account deaktivieren"])},
      "Proof_Performance_Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungsnachweis prüfen"])},
      "Process_Sepa_Transfer_File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepa Transfer File verarbeiten"])},
      "PermitTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterbildung genehmigen"])},
      "PayTraining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterbildung bezahlen"])},
      "UploadCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikat hochladen"])},
      "Attach_Certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikat hinzufügen"])}
    }
  },
  "employeeManagementPage": {
    "break": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszeit festlegen"])},
    "terminate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kündigungsdatum festlegen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter bearbeiten"])},
    "breakDialog-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszeit festlegen"])},
    "breakDialog-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum möchten Sie pausieren?"])},
    "terminateDialog-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kündigungsdatum festlegen"])}
  },
  "createButtonForm": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle ein benutzerdefiniertes Widget"])},
    "chooseLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle ein Logo"])},
    "widgetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "widgetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle das Widget"])}
  },
  "deleteButtonForm": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welches Widget möchten Sie löschen?"])}
  },
  "displayFiles": {
    "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiname"])},
    "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateityp"])},
    "fileCreatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
    "addFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei hinzufügen"])},
    "chooseFileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle den Dateityp"])}
  },
  "MISC-Button": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "backToHomePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Startseite"])}
  },
  "MISC": {
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis"])},
    "asOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ab"])}
  },
  "equipment": {
    "employeeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeitername"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
    "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seriennummer"])},
    "functional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktional"])},
    "broken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defekt"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nein"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
    "addNewHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Hardware einfügen"])},
    "createTransferOfUseFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsüberlassung erzeugen für"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
    "createHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware anlegen"])},
    "hardwareCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware angelegt"])},
    "updateHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware bearbeiten"])},
    "hardwareDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware gelöscht"])},
    "changedHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware aktualisiert"])},
    "giveName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Bezeichnung angeben"])},
    "giveSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Seriennummer angeben"])},
    "contractNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsnummer"])},
    "runTimeFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufzeit von"])},
    "runTimeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufzeit bis"])},
    "assignEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter zuordnen"])},
    "assignPhoneContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handyvertrag zuordnen"])},
    "selectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter wählen"])},
    "hardwareType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardwaretyp"])},
    "selectHardwareType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardwaretyp wählen"])},
    "addDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente hinzufügen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "notAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zugewiesen"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "deleteEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wollen Sie den Eintrag löschen?"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung"])},
    "invalidSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seriennummer ist ungültig!"])},
    "usedSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seriennummer bereits vergeben"])},
    "invalidName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name darf keine Sonderzeichen beinhalten!"])},
    "invalidContractNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsnummer ist nicht gültig!"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen..."])},
    "serialPrintLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - Seriennummer: "])},
    "serialNumberDuplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seriennummer bereits vergeben"])},
    "clearSearchField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchfeld leeren"])},
    "deleteHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware löschen"])},
    "createTransferOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsüberlassung erzeugen"])}
  },
  "Project": {
    "createProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Projekt erstellen"])},
    "editProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt bearbeiten"])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhang Projektbeschreibung"])},
    "msgArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wollen Sie den Eintrag archivieren?"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "createdProjectSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt erfolgreich erstellt"])},
    "changedProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt erfolgreich geändert"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
    "projectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektbeschreibung"])},
    "commissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragung"])},
    "commissionings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragungen"])},
    "noCommissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Beauftragung vorhanden"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektname"])},
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Projektnummer"])},
    "externalProjectId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Projektnummer"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis"])},
    "hourlyRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stundensatz"])},
    "dailyRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagessatz"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
    "selectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter wählen"])},
    "errorSelectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte einen Mitarbeiter auswählen"])},
    "errorDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Beschreibung angeben"])},
    "errorValidDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ein gültiges Start und Enddatum eingeben"])},
    "errorRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte einen Satz angeben"])},
    "errorProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte einen Projektnamen angeben"])},
    "errorProjectNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Projektnummer angeben"])},
    "errorProjectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Datei auswählen"])},
    "errorFillInFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden nicht alle Pflichtfelder ausgefüllt"])},
    "errorSelectClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte einen Kunden auswählen"])},
    "errorProjectId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ProjektId ist bereits vergeben"])},
    "errorProjectNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt wurde nicht gefunden"])},
    "errorNoUniqueProjectId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es existieren mehrere Projekte mit der angefragten Projekt-ID"])},
    "msgEntryExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag bereits vorhanden"])},
    "errorInternalProjectCannotBeArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das interne Projekt kann nicht archiviert werden."])},
    "errorCommissioningCannotBeCreatedWhenInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Beauftragung kann nicht für ein inaktives Projekt erstellt werden."])},
    "msgEntryExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überlappung mit anderem Eintrag"])},
    "msgEntryAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag hinzugefügt"])},
    "msgEntryArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag archiviert"])},
    "msgSelectCommissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte eine Beauftragung zum Bearbeiten auswählen"])},
    "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei wählen"])},
    "onlyNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte nur gültige Zeichen eingeben!"])},
    "timeSpecification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitangabe "])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunden"])},
    "projectDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekttage"])},
    "hoursPerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Std. pro Tag"])},
    "hoursOnSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Std. vor Ort"])},
    "agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinbarung"])},
    "warningClientNotSelectedWhenEditingSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Kunde ausgewählt"])},
    "warningClientNotSelectedWhenEditing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie einen Kunden aus, indem Sie auf eine Zeile in der Tablle klicken."])}
  },
  "client": {
    "basicInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisinformation"])},
    "clientAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenadresse"])},
    "invoiceAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsadresse"])},
    "msgClientCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde erfolgreich erstellt"])},
    "msgClientChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde erfolgreich geändert"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "msgError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden nicht alle Pflichtfelder ausgefüllt"])},
    "msgClientError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden konnte nicht gespeichert werden."])},
    "addClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde hinzufügen"])},
    "editClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde bearbeiten"])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhang Projektbeschreibung"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "billing-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsadresse"])},
    "contact-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt E-Mail"])},
    "invoice-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungs E-Mail"])},
    "phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "contact-person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer"])},
    "zip-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "errorEmptyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt E-Mail ist leer."])},
    "errorEmptyInvoiceEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungs E-Mail ist leer."])},
    "errorEmptyPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer ist leer."])},
    "errorEmptyContactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner ist leer."])},
    "errorEmptyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name ist leer."])},
    "errorEmptyCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt ist leer."])},
    "errorEmptyZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ ist leer."])},
    "errorEmptyStreet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße ist leer."])},
    "errorEmptyHouseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer ist leer."])},
    "msgArchiveEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wollen Sie den Eintrag archivieren?"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
    "takeClientAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenadresse übernehmen"])}
  },
  "vacationDaysPage": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaubsantrag"])},
    "requestTimeOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaub beantragen"])},
    "selectVacationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaubstyp auswählen"])},
    "selectTheDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum auswählen"])},
    "timeOfPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt:"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar:"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
    "addNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füg einen Kommentar hinzu"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochenenden und Feiertage zählen nicht als arbeitsfreie Tage."])},
    "currentState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status dieses Urlaubs"])},
    "requestMadeBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Urlaubsanfrage wurde gemacht von"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "maxCharsComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können noch "])},
    "chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" weitere Zeichen eingetragen werden"])},
    "holiday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feiertag"])}
  },
  "vacationDaysPage-tabs": {
    "showPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehend anzeigen"])},
    "showApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigt anzeigen"])},
    "showRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt anzeigen"])}
  },
  "vacationDaysPage-tabs-requests": {
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANTRÄGE"])},
    "teamMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEAMMITGLIED"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZEITRAUM"])},
    "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANGEFRAGT"])},
    "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TYP"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STATUS"])},
    "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigt"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austehend"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
    "requestMadeByManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage vom Manager gestellt"])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URLAUB"])},
    "specialLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SONDERURLAUB"])},
    "unpaidVacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UNBEZAHLTER URLAUB"])},
    "annualLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JAHRESURLAUB"])},
    "dateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD.MM.yy"])},
    "dateFormat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD.MM"])},
    "showTimeOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie den Urlaubstyp aus."])},
    "monthNames": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januar"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februar"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["März"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezember"])}
    ],
    "monthNamesShort": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feb"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mär"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apr"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jul"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aug"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sep"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okt"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nov"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dez"])}
    ]
  },
  "vacationDaysPage-tabs-balances": {
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZUSAMMENFASSUNG"])},
    "accrued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANSPRUCH"])},
    "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERBRAUCHT"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERFÜGBAR"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "balanceHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie"])},
    "balanceHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilanz"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URLAUB"])}
  },
  "vacationDaysPage-tabs-timeline": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZEITLEISTE"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heute"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])}
  },
  "vacationDaysPage-toast-msg": {
    "summarySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsmeldung"])},
    "successDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antrag auf Urlaub"])},
    "summaryError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info-Nachricht"])},
    "summaryErrorDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An diesen Tagen wurde bereits Urlaub beantragt."])},
    "errorDetailMsgForAlreadyTookVacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überschneidender Urlaub"])},
    "errorDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaubsantrag abgelehnt"])},
    "errorDetailMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "entryErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Eintrag kann nicht gespeichert werden, da nicht alle Felder ausgefüllt sind."])},
    "entrySuccessMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag erfolgreich gespeichert"])},
    "entryUnknownMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Fehler"])},
    "errorDuplicateMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Duplizieren des Eintrags"])},
    "vacationStartsTodayOrInPast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast diesen Urlaub schon genommen und kannst diesen nicht mehr löschen."])},
    "summeryMessageDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du willst keinen Urlaub nehmen."])},
    "rejectedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
    "rejectedMsgDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast abgelehnt."])},
    "applicationRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antrag abgelehnt"])},
    "notEnoughVacations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast nicht genug Urlaub."])},
    "pendingMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anstehend"])},
    "pendingMsgForDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten, bitte neuladen und erneut versuchen."])}
  },
  "vacationsDaysTask": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe - Urlaub Antrag"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum:"])},
    "vacationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zu den Urlaubstagen"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Datum:"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende Datum:"])},
    "vacationsType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaubstyp:"])},
    "remainingVacationsDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resturlaub:"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage:"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar:"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])}
  },
  "vacationsDaysTask-confirmDialog-msg": {
    "msgDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mitarbeiter hat keinen ausreichenden Urlaubsanspruch."])},
    "msgDetailQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diesen Antrag trotzdem genehmigen?"])},
    "confirmMsgForDeleteQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie sich zurückziehen möchten?"])},
    "confirmMsgForDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung"])}
  },
  "vacationsDaysTask-toast-msg": {
    "successSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigt"])},
    "successDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaubstage akzeptiert"])},
    "rejectSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
    "rejectDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaubstage abgelehnt"])}
  },
  "contractModificationPage": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsänderung"])},
    "employeeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter Name"])},
    "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag gültig ab"])},
    "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehalt"])},
    "workingHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochenstunden"])},
    "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus"])},
    "contractUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag hochladen"])},
    "createNewContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Vertrag erstellen"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei wählen"])},
    "validation-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld ist erforderlich!"])},
    "successToast-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
    "successToast-detailMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["einen neuen Vertrag erstellt."])},
    "errorToast-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "errorToast-detailMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Erstellen des Vertrags"])},
    "placeholder-employee-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter wählen"])}
  },
  "skills": {
    "PROFIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROFIL"])},
    "SKILLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKILLS"])},
    "PROJEKTAUSZUG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROJEKTAUSZUG"])},
    "CODING_LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmiersprachen"])},
    "FRAMEWORK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frameworks"])},
    "DATABASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenbanken"])},
    "TOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitätssicherung"])},
    "SECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branche"])},
    "ROLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle(n)"])},
    "STACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stack"])},
    "METHOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methodik"])},
    "newEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Eintrag"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "ZERTIFIKATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZERTIFIKATE"])},
    "LANGUAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPRACHEN"])}
  },
  "required": {
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderliches Feld"])}
  },
  "overviewOfEmployee": {
    "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Daten"])},
    "accountingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungsdaten"])},
    "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsdaten"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "errorDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter konnte nicht gespeichert werden."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
    "successDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten werden verglichen."])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "checkValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie die Validierung"])}
  },
  "confirmation": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum / Monat"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachweis"])}
  },
  "expenseAccounting": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "addExpense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgabe hinzufügen"])},
    "viewOnlyExpense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgabe betrachten"])},
    "updateExpense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgabe aktualisieren"])},
    "employeeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
    "dayOfExpense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "expenseCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
    "chooseCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie wählen"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
    "priceTotalBrutto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbetrag (brutto)"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei wählen"])},
    "expenseFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belege"])},
    "status": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
      "sepaSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überwiesen"])},
      "toDoSepa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird überwiesen"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird geprüft"])},
      "notSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht eingereicht"])}
    },
    "billingPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungszeitraum"])},
    "sumPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
    "addExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Spesenabrechnung erstellen"])},
    "submitReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesenreport einreichen"])},
    "nameExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung für Report"])},
    "deleteExpenseQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Spese löschen?"])},
    "deleteExpenseReportQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Spesenabrechnung löschen?"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung"])},
    "checkInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feld ist erforderlich"])},
    "emptyExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesenabrechnung enthält keine Ausgaben"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
  },
  "processExpenseReportSepaTask": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPA-Überweisung"])},
    "downloadSepa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEPA-Datei herunterladen"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung bestätigen"])}
  },
  "attestAttachTask": {
    "filesEmptyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde keine Datei angehängt."])}
  },
  "orderHardware": {
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Hardware benötigst du?"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für deine Arbeit aus dem heimischen Office stellen wir dir eine Standardausstattung bestehend aus:"])},
    "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solltest du einige Bestandteile nicht benötigen, weil du bereits voll ausgestattet bist oder aus Platzgründen nicht alles stellen kannst, hast du hier die Möglichkeit deine Ausstattung nach deinen Anforderungen zusammenzustellen. Nur das MacBook kannst du nicht abbestellen, das erhältst du immer."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absenden"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen"])},
    "orderCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke, deine Auswahl wurde übermittelt."])},
    "confirmDialogHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hast du alles richtig ausgewählt?"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
  },
  "consentForm": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "dataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "agreePoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich stimme den folgenden Punkten zu:"])},
    "checkboxDataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ich willige in die Datenverarbeitung gemäß der Datenschutzerklärung ein"])},
    "checkboxBirthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ich stimme der internen Weitergabe meines Geburtsdatums zu"])},
    "errorMissingConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen dem Datenschutzformular zustimmen."])}
  },
  "birthdayCalendar": {
    "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heute"])}
  },
  "performanceRecordManagement": {
    "createPerformanceRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungsnachweis erstellen"])},
    "submitPerformanceRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungsnachweis einreichen"])},
    "registerEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email registrieren"])}
  },
  "reviewExpenseReportTask": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review - Spesenabrechnung"])},
    "expenseReportDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details Spesenabrechnung"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "entrydate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angestellt am:"])},
    "dayOfExpense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "expenseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "expenseCategoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt Betrag (brutto)"])},
    "hiringDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angestellt am:"])},
    "nameExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "statusExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "sumPriceExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt Betrag (brutto)"])},
    "monthYearExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungszeitraum"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
    "viewExpenseReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesenabrechnung prüfen"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])}
  },
  "adminConfigurations": {
    "workHoursLockDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage bis Arbeitszeiten nicht mehr bearbeitbar sind"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])},
    "changingValueSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern des Wertes war erfolgreich"])},
    "dataProtectionConfirmationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Datenschutzerklärung bestätigen"])},
    "bothTextsAltered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie die Datenschutzerklärung in beiden Sprachen aktualisiert?"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "updatePrivacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung bearbeiten"])},
    "englishVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englische Version"])},
    "germanVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsche Version"])},
    "changingPrivacyPolicySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern der Datenschutzerklärung war erfolgreich"])},
    "requestVacationForOtherEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaub für einen anderen Mitarbeiter beantragen"])},
    "selectEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter auswählen"])},
    "requestVacationFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaub beantragen für"])},
    "notEverythingFilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind nicht alle erforderlichen Felder ausgefüllt"])},
    "timeTrackingConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitszeiterfassung Konfiguration"])},
    "dataProtectionConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz Konfiguration"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortfahren"])},
    "createAnnualLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter Jahresurlaub bearbeiten"])},
    "updateEmployeeAnnualLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresurlaub bearbeiten für"])},
    "annualLeaveDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresurlaubsanspruch"])},
    "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld ist erforderlich!"])},
    "summarySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgsmeldung"])},
    "successDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresurlaub wurde erfolgreich bearbeitet."])},
    "documentOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenübersicht"])}
  },
  "days": {
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])}
  },
  "validation": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validierungsfehler"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine gültige Iban"])},
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine gültige Bic"])}
  },
  "errorPage": {
    "errorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Fehler kann verschiedene Gründe haben, unter anderem, dass das Backend nicht erreichbar ist oder kein Nutzer gefunden wurde."])}
  },
  "report": {
    "vacationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaubsreport"])},
    "timeTrackingReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiterfassungsreport"])},
    "cashFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldflussvorhersage"])},
    "VACATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaub"])},
    "OFFBOARDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offboarding"])},
    "UNPAIDVACATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbezahlter Urlaub"])},
    "SPECIALLEAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonderurlaub"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaubsanträge"])},
    "annualLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresurlaubsübersicht"])},
    "selectRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereich auswählen"])},
    "filterByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Namen filtern"])},
    "filterByUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Benutzer filtern"])},
    "filterByType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Typ filtern"])},
    "selectStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status auswählen"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "filterByAnnualLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Urlaubsanspruch filtern"])},
    "filterByUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach verbrauchten Urlaubstagen filtern"])},
    "filterByAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach verfügbaren Urlaubstagen filtern"])},
    "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum auswählen"])}
  },
  "timeTrackingReport": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitete an"])},
    "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektname"])},
    "projectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektbeschreibung"])},
    "billable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungsfähig"])},
    "startTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startzeit"])},
    "endTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endzeit"])},
    "calendarValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "ticketId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticketnummer"])},
    "Remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote"])},
    "filterByTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Ticket filtern"])}
  },
  "docOveriew": {
    "docType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art des Dokuments"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "docName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiname"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])}
  },
  "cashFlow": {
    "cashFlowForeCast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldflussvorhersage"])}
  },
  "absence": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaub stornieren"])},
    "summarySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaub erfolgreich storniert"])}
  }
}