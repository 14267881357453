<template>
  <div>
    <Toast/>
    <TimBreadCrumb></TimBreadCrumb>
    <div class="p-grid">
      <div class="p-col-6 p-offset-3">
        <div class="home">
          <Steps ref="steps" class="pb-4" :model="stepItems">
            <template #item="{ item }">
              <span
                  class="p-menuitem-link router-link-active router-link-active-exact"
                  :class="item.step <= step ? 'cursor-pointer' : ''"
                  role="presentation"
                  @click="
                  item.step <= step ? switchStep(item.step, true) : {}
                "
              >
                <span
                    class="p-steps-number"
                    :class="stepClass(item.step)"
                >{{ item.step + 1 }}</span
                >
                <span class="p-steps-title">{{ item.label }}</span>
              </span>
            </template>
          </Steps>
          <div v-if="!submitted">
            <component
                :is="steps[step].component"
                :form-value="formValue[steps[step].label]"
                :schema="steps[step].validationSchema"
                @formValueChange="updateFormValue"
            />

            <div id="outer" class="pt-3 px-4">
              <div class="p-grid">
                <div v-if="step > 0" class="p-col-6">
                  <Button
                      class="w-12"
                      :label="$t('MISC-Button.previous')"
                      @click="previous"
                  />
                </div>
                <div
                    v-if="step < steps.length - 1"
                    :class="step === 0 ? 'p-col-12' : 'p-col-6'"
                >
                  <Button
                      class="w-12"
                      :label="$t('MISC-Button.next')"
                      data-cy="next"
                      @click="next"
                  />
                </div>
                <div v-else class="p-col-6">
                  <Button
                      type="submit"
                      class="w-12"
                      style="background: #4caf50"
                      :label="$t('MISC-Button.submit')"
                      @click.prevent="submitForm"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
              v-else
              class="text-center text-5xl font-bold shadow-lg p-10"
              data-cy="complete"
          >
            <span
                v-if="state.formValue.id == null"
                class="text-green-500"
            >{{ $t("client.msgClientCreated") }}</span
            >
            <span v-else class="text-green-500">{{
                $t("client.msgClientChanged")
              }}</span>
            <br/>
            <Button style="font-size: 20px" @click="redirectToHome">{{
                $t("client.finish")
              }}
            </Button>
          </div>

          <div v-if="error" class="alert">
            <span
                class="closeButton"
                onclick="this.parentElement.style.display='none';"
            >&times;</span
            >
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddClientBasicInfo from "./AddClientBasicInfo";
import AddClientAddress from "./AddClientAddress";
import AddClientBillingAddress from "./AddClientBillingAddress";
import global from "./newClient";
import Card from "primevue/card";
import Button from "primevue/button";
import useVuelidate from "@vuelidate/core";
import ClientService from "@/service/ClientService";

export default {
  name: "InputData",
  components: [
    AddClientBasicInfo,
    AddClientAddress,
    AddClientBillingAddress,
    Button,
    Card,
  ],
  setup() {
    const {state} = global;
    return {
      state,
    };
  },
  data() {
    return {
      v$: useVuelidate(),
      clientId: this.$route.params.clientId,
      stepItems: [
        {label: this.$t("client.basicInfos"), to: "/client", step: 0},
        {
          label: this.$t("client.clientAddress"),
          to: "/client",
          step: 1,
        },
        {
          label: this.$t("client.invoiceAddress"),
          to: "/client",
          step: 2,
        },
      ],
      error: false,
      message: "",
      step: 0,
      submitted: false,

      formValue: {
        AddClientBasicInfo: {
          contactEmail: "",
          invoiceEmail: "",
          phoneNumber: "",
          contactPerson: "",
          name: "",
        },
        AddClientAddress: {
          tee: 24,
          street: "",
          number: "",
          zipCode: "",
          city: "",
        },
        AddClientBillingAddress: {
          street: "",
          number: "",
          zipCode: "",
          city: "",
        },
      },
      steps: [
        {
          component: AddClientBasicInfo,
          label: "AddClientBasicInfo",
        },
        {
          component: AddClientAddress,
          label: "AddClientAddress",
        },
        {
          component: AddClientBillingAddress,
          label: "AddClientBillingAddress",
        },
      ],
    };
  },

  computed: {
    stepTitle() {
      if (this.step === 0) return "AddClientBasicInfo";
      if (this.step === 1) return "AddClientAddress";
      if (this.step === 2) return "AddClientBillingAddress";
      return "";
    },
  },
  beforeMount() {
    if (this.state.formValue.id) {
      this.$route.meta.breadcrumb.label = this.$t("client.editClient");
    } else {
      this.$route.meta.breadcrumb.label = this.$t("client.addClient");
    }
  },
  mounted() {
    if (this.clientId) {
      ClientService.getClient(this.clientId).then(response => {
        const client = response.data;
        global.state.formValue.id = client.id;
        global.state.formValue.contactEmail = client.contactEmail;
        global.state.formValue.invoiceEmail = client.invoiceEmail;
        global.state.formValue.phoneNumber = client.phoneNumber;
        global.state.formValue.contactPerson = client.contactPerson;
        global.state.formValue.name = client.name;
        global.state.formValue.street = client.address.street;
        global.state.formValue.number = client.address.number;
        global.state.formValue.zipCode = client.address.zipCode;
        global.state.formValue.city = client.address.city;
        global.state.formValue.street = client.billingAddress.street;
        global.state.formValue.number = client.billingAddress.number;
        global.state.formValue.zipCode = client.billingAddress.zipCode;
        global.state.formValue.city = client.billingAddress.city;
      });
    }

  },
  methods: {
    stepClass(step) {
      if (step === this.step) return "active";
      if (step < this.step) return "success";
      return "";
    },

    next() {
      this.switchStep(this.step + 1);
    },

    previous() {
      this.switchStep(this.step - 1, true);
    },

    validate(forceSwitchPage) {
      if (forceSwitchPage) {
        return true;
      }

      this.v$.$validate();

      if (this.v$.$error) {
        this.$toast.add({
          severity: "error",
          summary: this.$t("client.error"),
          detail: this.$t("client.msgError"),
          life: 5000,
        });
      }
      return !this.v$.$error;
    },

    switchStep(step, forceSwitchPage) {
      //const stepComponent = this.steps[this.step];
      if (this.validate(forceSwitchPage)) {
        this.step = step;
        return this.step;
      }
    },

    async submitForm() {
      const clientJson = JSON.stringify(this.state.formValue);

      if (!this.validate()) {
        return;
      }

      ClientService.saveClient(clientJson)
          .then((response) => {
            if (response && response.status === 200) {
              this.submitted = true;
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("client.error"),
                detail: this.$t("client.msgClientError"),
                life: 5000,
              });
            }
          })
          .catch((error) => {
            this.$toast.add({
              severity: "error",
              summary: this.$t("client.error"),
              detail: error.response ? error.response.data : error.message,
              life: 5000,
            });
          });
    },

    updateFormValue(payload) {
      this.formValue = {
        ...this.formValue,
        [payload.label]: payload.data,
      };
    },

    redirectToHome() {
      this.$router.push({name: "ClientManagement"});
    },
  },
};
</script>

<style scoped>
#outer {
  width: 100%;
  text-align: center;
}

.inner {
  display: inline-block;
  width: 250px;
  padding: 20px 75px;
  margin: 10px 15px;
  font-size: 20px;
  font-weight: bold;
}

/* The alert message box */
.alert {
  padding: 20px;
  background-color: #f44336; /* Red */
  color: white;
  margin-bottom: 15px;
}

/* The close button */
.closeButton {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closeButton:hover {
  color: black;
}

.p-steps .p-steps-item.p-highlight .p-steps-number:not(.active) {
  background: #666;
  color: #ffffff;
}

.p-steps .p-steps-item.p-highlight .p-steps-number.active {
  background: #464df2;
  color: #ffffff;
}

.p-steps .p-steps-item.p-highlight .p-steps-number.success {
  background: #188a42;
  color: #ffffff;
}
</style>
